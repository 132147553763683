import React from 'react';
import DrawerLayout from '../../containers/DrawerLayout';
import ConversationHistory from '../../components/ConversationHistory';

const { Title, Content } = DrawerLayout;

const FeedbackView = function () {
  return (
    <DrawerLayout>
      <Title>Positive Feedbacks</Title>
      <Content>
        <ConversationHistory positiveFeedback />
      </Content>
    </DrawerLayout>
  );
};

export default FeedbackView;
