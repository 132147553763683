import styled from 'styled-components';
import { Layout } from 'antd';

const PageFooter = styled(Layout.Footer)`
  background: ${({ theme }) => theme.backgroundColor};

  a {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export default PageFooter;
