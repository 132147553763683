import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { List, Row, Space } from 'antd';
import styled from 'styled-components';
import ChatItem from './ChatItem';

const ListHeader = styled(Row)`
  padding: 0 15px;
`;

const ChatsByState = function ({
  chats,
  icon,
  title,
  loadMore = null,
  sortByLastMessage = false,
  sortByCreatedAt = false,
}) {
  if (chats?.length === 0) {
    return null;
  }

  const getDatasource = () => {
    let sortedChats = chats;
    if (sortByLastMessage) {
      sortedChats = orderBy(
        chats,
        (chat) => (
          Math.max(
            chat?.lastUserMessageAt || 0,
            chat?.lastExpertMessageAt || 0,
          )
        ),
        'desc',
      );
    }
    if (sortByCreatedAt) {
      sortedChats = orderBy(chats, 'createdAt', 'asc');
    }
    return sortedChats;
  };

  return (
    <List
      header={(
        <ListHeader>
          <Space size={4}>
            {icon}
            {title}
          </Space>
        </ListHeader>
      )}
      rowKey={(chat) => chat.id}
      dataSource={getDatasource(chats)}
      loadMore={loadMore}
      renderItem={(chat) => (
        <ChatItem chat={chat} />
      )}
    />
  );
};

ChatsByState.propTypes = {
  chats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  loadMore: PropTypes.node,
  sortByLastMessage: PropTypes.bool,
  sortByCreatedAt: PropTypes.bool,
};

export default ChatsByState;
