import React from 'react';
import { Card } from 'antd';
import DrawerLayout from '../../../containers/DrawerLayout';
import PaymentSettings from '../../../components/settings/PaymentSettings';

const { Title, Content } = DrawerLayout;

const PaymentSettingsView = function () {
  return (
    <DrawerLayout>
      <Title>Payment</Title>
      <Content>
        <Card>
          <PaymentSettings />
        </Card>
      </Content>
    </DrawerLayout>
  );
};

export default PaymentSettingsView;
