import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Row, Col } from 'antd';

const ErrorMessages = function ({ message }) {
  if (!message) {
    return null;
  }
  return (
    <Row type="flex">
      <Col span={24} style={{ margin: '15px 0px' }}>
        <Alert
          message={message}
          type="error"
          showIcon
        />
      </Col>
    </Row>
  );
};

ErrorMessages.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessages;
