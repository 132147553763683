import { gql } from '@apollo/client';

const expertInvitation = gql`
  query GetExpertInvitation($uid: ID!) {
    expertInvitation(uid: $uid) {
      uid,
      name,
      email,
      role,
      partner{
        id,
        name,
      }
      createdAt,
      categories,
    }
  }
`;

export default expertInvitation;
