import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import PARTNER_CATEGORIES from './queries/getPartnerCategories';
import ExpertiseSwitchItem from './ExpertiseSwitchItem';
import ExpertiseJoinItem from './ExpertiseJoinItem';

const ExpertiseCard = function ({ categories, partnerId, isEnabled }) {
  const {
    data: {
      partner: {
        categories: partnerCategories = [],
      } = {},
    } = {},
  } = useSuspenseQuery(PARTNER_CATEGORIES, {
    variables: {
      id: partnerId,
      active: true,
    },
  });

  const joinedCategories = partnerCategories.filter(({ id }) => (
    categories?.find(({ node: { id: joinedId } }) => id === joinedId)
  ));

  const unjoinedCategories = partnerCategories.filter(({ id }) => (
    !joinedCategories?.find(({ id: joinedId }) => id === joinedId)
  ));

  if (joinedCategories.length <= 1 && unjoinedCategories.length <= 0) {
    return null;
  }

  return (
    <Card title="AREAS OF EXPERTISE">
      {joinedCategories.map((category) => {
        const expertise = categories.find((
          ({ node: { id: joinedId } }) => category.id === joinedId
        ));
        if (expertise?.node.id === 'general') {
          return null;
        }

        return (
          <ExpertiseSwitchItem
            key={expertise.node.id}
            partnerId={partnerId}
            communityEnabled={isEnabled}
            expertise={expertise}
          />
        );
      })}
      {unjoinedCategories.map((category) => (
        <ExpertiseJoinItem
          key={category.id}
          partnerId={partnerId}
          category={category}
        />
      ))}
    </Card>
  );
};

ExpertiseCard.propTypes = {
  partnerId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ExpertiseCard;
