import React from 'react';
import { Col } from 'antd';
import ProfileButton from './ProfileButton';
import Header from './styles/Header';
import SubHeader from './styles/SubHeader';
import NewsButton from './news/NewsButton';
import CommunitiesButton from './communities/CommunitiesButton';
import MoreMenuButton from './MoreMenu';
import FeedbackButton from './FeedbackButton';
import RatingButton from './RatingButton';
import BalanceButton from './BalanceButton';

const InboxHeader = function () {
  return (
    <>
      <Header justify="end">
        <Col flex="1">
          <ProfileButton />
        </Col>
        <Col flex="none">
          <CommunitiesButton />
          <NewsButton />
          <MoreMenuButton />
        </Col>
      </Header>
      <SubHeader justify="space-around">
        <BalanceButton />
        <section>
          <RatingButton />
          <FeedbackButton />
        </section>
      </SubHeader>
    </>
  );
};

export default InboxHeader;
