import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import Empty from '../../../Empty';

const NewsEmpty = function () {
  return (
    <Empty
      image={<BellOutlined />}
      title="There are no news right now"
      description="News about your communities will appear here."
    />
  );
};

export default NewsEmpty;
