import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  #app,
  .ant-app,
  body {
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
  }

  .ant-layout {
    width: 100%;
  }

  .ant-dropdown-menu {
    max-height: 500px;
    overflow: auto;
  }
`;

export default GlobalStyle;
