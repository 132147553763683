import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { useQuery } from '@apollo/client';
import GET_PARTNER_LOGO from './queries/getPartnerLogoUrl';

const PartnerAvatar = function ({ id, ...props }) {
  const {
    data: {
      partner: {
        settings: {
          appearance: {
            chatWidgetPhotoUrl,
          } = {},
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_PARTNER_LOGO, {
    variables: { id },
  });
  return (
    loading
      ? <Avatar style={{ backgroundColor: 'transparent' }} {...props} />
      : <Avatar src={chatWidgetPhotoUrl} {...props} />
  );
};

PartnerAvatar.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PartnerAvatar;
