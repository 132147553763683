import React from 'react';
import { List } from 'antd';
import { useLiveQuery } from '@guuru/react-hooks';
import { LoadMore } from '@guuru/react-pagination';
import NewsEmpty from './NewsEmpty';
import NewsItem from './NewsItem';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import GET_PARTNER_NEWS from '../queries/getExpertPartnersNews';
import NEWS_BY_EXPERT_UPDATED from '../subscriptions/newsUpdated';

const NewsList = function () {
  const { id: userId, locale } = useCurrentUser();

  const {
    loading: isLoadingNews,
    data: {
      expert: {
        news: allNews = {},
      } = {},
    } = {},
    fetchMore,
  } = useLiveQuery(GET_PARTNER_NEWS, NEWS_BY_EXPERT_UPDATED, {
    queryOptions: {
      variables: {
        id: userId,
      },
    },
  });

  return (
    <>
      <List
        itemLayout="vertical"
        locale={{ emptyText: <NewsEmpty /> }}
        dataSource={allNews?.edges}
        loading={isLoadingNews}
        renderItem={({ node }) => <NewsItem item={node} locale={locale} />}
      />
      <LoadMore
        fetchMore={fetchMore}
        data={allNews}
      />
    </>
  );
};

export default NewsList;
