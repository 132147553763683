import { createContext } from 'react';

const DrawerStackContext = createContext({
  open: () => {},
  close: () => {},
  closeAll: () => {},
  stack: [],
});

export const DrawerStackProvider = DrawerStackContext.Provider;

export default DrawerStackContext;
