import React from 'react';
import { useSuspenseQuery, useMutation } from '@apollo/client';
import {
  List,
  Switch,
  Alert,
  Row,
  Col,
} from 'antd';
import { error, success } from '@guuru/react-message';
import GET_EXPERT from './queries/expert';
import SWITCH_LANGUAGE from './mutations/switchLanguage';
import useCurrentUser from '../../../hooks/useCurrentUser';
import ListItem from '../../ListItem';

const LanguageSettings = function () {
  const { id: expertId } = useCurrentUser();
  const {
    data: {
      expert: {
        languages,
      } = {},
      languages: allLanguages,
    } = {},
  } = useSuspenseQuery(GET_EXPERT, {
    variables: {
      id: expertId,
    },
  });

  const [
    updateLanguage, {
      error: updateLanguageError,
    },
  ] = useMutation(SWITCH_LANGUAGE);

  const toggleEnableLanguage = async (locale, enabled) => {
    await updateLanguage({
      variables: {
        expertId,
        language: locale.code,
        value: enabled,
      },
    });
    if (updateLanguageError) {
      // log this
      error('Something went wrong...');
    }
    success('Chat Languages updated');
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Alert message="Languages you give support in" type="info" showIcon />
      </Col>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={allLanguages}
          renderItem={(locale) => {
            const checked = languages?.includes(locale.code);
            return (
              <ListItem>
                <Row justify="space-between" align="middle">
                  <List.Item.Meta
                    title={locale.name}
                    description={locale.nameTranslate}
                  />
                  <Switch
                    defaultChecked={checked}
                    onChange={(enabled) => (
                      toggleEnableLanguage(locale, enabled)
                    )}
                    unCheckedChildren="Enable"
                    checkedChildren="Disable"
                  />
                </Row>
              </ListItem>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default LanguageSettings;
