import React, { createContext, useEffect } from 'react';
import { Sentry } from '@guuru/sentry-web';
import PropTypes from 'prop-types';
import useFetchCurrentUser from '../hooks/useFetchCurrentUser';
import useInitLocale from '../hooks/useInitLocale';

const AuthContext = createContext({
  user: undefined,
  isAccountVerified: false,
  isTosAccepted: false,
});

export const UserProvider = function ({ children }) {
  const auth = useFetchCurrentUser();
  useInitLocale('en');

  const { loading, user } = auth;

  useEffect(() => {
    if (!loading) {
      Sentry.getCurrentScope()
        .setUser({ email: user.email })
        .setExtra('guuruId', user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
