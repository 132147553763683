import styled from 'styled-components';
import { Modal } from 'antd';

const OnboardingModal = styled(Modal)`
  top: 0;
  padding: 32px;

  .ant-modal-content {
    max-height: calc(100vh - 64px);
    display:flex;
    flex-direction:column;

    .ant-modal-body {
      margin-top: 10px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
`;

export default OnboardingModal;
