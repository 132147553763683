import React from 'react';
import { Card } from 'antd';
import DrawerLayout from '../../../containers/DrawerLayout';
import UpdateEmail from '../../../components/settings/AdvancedSettings/UpdateEmail';
import UpdatePassword
  from '../../../components/settings/AdvancedSettings/UpdatePassword';
import DeleteAccount
  from '../../../components/settings/AdvancedSettings/DeleteAccount';

const { Title, Content } = DrawerLayout;

const AdvancedSettingsView = function () {
  return (
    <DrawerLayout>
      <Title>Advanced</Title>
      <Content>
        <Card title="UPDATE EMAIL">
          <UpdateEmail />
        </Card>
        <Card title="UPDATE PASSWORD">
          <UpdatePassword />
        </Card>
        <Card title="DELETE ACCOUNT">
          <DeleteAccount />
        </Card>
      </Content>
    </DrawerLayout>
  );
};

export default AdvancedSettingsView;
