const currencies = {
  EUR: {
    name: 'EUR',
    symbol: '€',
    color: '#00B0FF',
  },
  CHF: {
    name: 'CHF',
    symbol: 'CHF',
    color: '#19A979',
  },
  USD: {
    name: 'USD',
    symbol: '$',
    color: '#f8c82e',
  },
  GBP: {
    name: 'GBP',
    symbol: '£',
    color: '#f69899',
  },
  CAD: {
    name: 'CAD',
    symbol: 'CA$',
    color: '#f56969',
  },

  XXX: {
    name: 'PTS',
    symbol: 'PTS',
    color: '#6C8893',
  },
};

const currencyNames = Object.values(currencies).map((value) => value.name);
const currencyCodes = Object.keys(currencies);
const ibanCurrencies = [currencies.EUR.name, currencies.CHF.name];
const noIbanCurrencies = [
  currencies.USD.name,
  currencies.GBP.name,
  currencies.CAD.name,
];
const bicCurrencies = [...ibanCurrencies, currencies.CAD.name];

export {
  currencies,
  currencyNames,
  currencyCodes,
  ibanCurrencies,
  noIbanCurrencies,
  bicCurrencies,
};
