import React, { useCallback, useEffect } from 'react';
import {
  requestPermissionIfNeeded,
  updateUserTokenIfNeeded,
} from '@guuru/fcm-web';
import log from '@guuru/logger-web';
import { App as AntContext } from 'antd';
import useCurrentUser from '../../../../hooks/useCurrentUser';

let hasNotification = false;

const useNotificationsCheck = function () {
  const user = useCurrentUser();
  const { notification } = AntContext.useApp();

  const showNotification = useCallback((hasError) => {
    if (hasNotification) return;

    const message = hasError
      ? 'This browser does not support push notifications. '
      : 'To receive notifications you must enable them in your browser. ';
    const learnMore = hasError
      ? 'https://caniuse.com/#feat=push-api'
      : 'https://bit.ly/2ohQsDq';

    notification.warning({
      message: 'Notifications are disabled',
      description: (
        <>
          {message}
          <a target="_blank" rel="noopener noreferrer" href={learnMore}>
            Learn more
          </a>
        </>
      ),
      key: 'push-notifications',
      duration: 0,
    });
    hasNotification = true;
  }, [notification]);

  useEffect(() => {
    const requestNotifications = async () => {
      try {
        const notificationsResult = await requestPermissionIfNeeded();
        if (!notificationsResult?.enabled) {
          showNotification(notificationsResult.error);
        }
        updateUserTokenIfNeeded(user.id, notificationsResult.pushToken);
      } catch (error) {
        log.error(error);
        showNotification(true);
      }
    };
    if (user.uid && user.isAccountVerified) {
      requestNotifications();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNotificationsCheck;
