import { gql } from '@apollo/client';

export default gql`
  query GetPartnerPricing($id: ID!) {
    partner(id: $id) {
      id
      name
      description
      pricing(types: ["good chat reward"]) {
        currency
      }
      settings {
        id
        appearance {
          chatWidgetPhotoUrl
        }
        community {
          id
          requiresVerify
          recruiting
        }
      }
      categories(active: true) {
        id
        name
        requiresVerify
      }
    }
  }
`;
