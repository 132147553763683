import { Button, theme } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const { useToken } = theme;

const IconButton = function ({ icon, onClick = () => {} }) {
  const { token } = useToken();
  return (
    <Button
      type="text"
      size="large"
      shape="circle"
      icon={icon}
      style={{ color: token.colorIcon }}
      onClick={onClick}
    />
  );
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default IconButton;
