import React from 'react';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { Card } from 'antd';
import styled from 'styled-components';
import useInbox from './hooks/useInbox';
import ChatsByState from '../ChatsByState';
import Loading from '../Loading';
import useCurrentUser from '../../../hooks/useCurrentUser';
import EmptyInbox from '../../../assets/images/EmptyInbox.svg';
import Empty from '../../Empty';

const EmptyChats = function () {
  return (
    <Card>
      <Empty
        image={<EmptyInbox />}
        title="There are no active requests"
        description="This list is updated automatically in realtime"
      />
    </Card>
  );
};

const ChatsList = styled.div`
  overflow: auto;
`;

const ActiveConversations = function () {
  const { id: expertId } = useCurrentUser();

  const {
    isLoading,
    newChats,
    runningChats,
  } = useInbox(expertId);

  if (isLoading) {
    return <Loading />;
  }

  if (!newChats.length && !runningChats.length) {
    return <EmptyChats />;
  }

  return (
    <ChatsList>
      <ChatsByState
        title="New Requests"
        icon={<ClockCircleOutlined />}
        chats={newChats}
        sortByCreatedAt
      />
      <ChatsByState
        title="Open"
        icon={<InboxOutlined />}
        chats={runningChats}
        useSubscriptions
        sortByLastMessage
      />
    </ChatsList>
  );
};

export default ActiveConversations;
