import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Space, Switch, Typography,
} from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { error, success } from '@guuru/react-message';
import log from '@guuru/logger-web/src/logger';
import { useMutation } from '@apollo/client';
import { LocaleHelper } from '@guuru/helpers-common';
import UPDATE_MEMBERSHIP from './mutations/updateMembership';

const { Text } = Typography;

const { localize } = LocaleHelper;

const ChatsEnabler = function ({ partnerId, name, isEnabled }) {
  const [
    updateMembership,
    { loading: isUpdating },
  ] = useMutation(UPDATE_MEMBERSHIP);

  const toggleEnableChats = async (newState) => {
    try {
      await updateMembership({
        variables: {
          partnerId,
          membership: {
            isEnabled: newState,
          },
        },
      });
      success(`Chats ${newState ? 'enabled' : 'disabled'} in
      ${localize(name)} community`);
    } catch (e) {
      log.error(e);
      error(e.message);
    }
  };

  return (
    <Row justify="space-between">
      <Space>
        <MessageOutlined />
        <Text>Enabled (Receive Chats)</Text>
      </Space>
      <Switch
        loading={isUpdating}
        defaultChecked={isEnabled}
        onChange={() => toggleEnableChats(!isEnabled)}
        size="middle"
      />
    </Row>
  );
};

ChatsEnabler.propTypes = {
  partnerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default ChatsEnabler;
