import React from 'react';
import { Card } from 'antd';
import DrawerLayout from '../../containers/DrawerLayout';
import EmptyRating from '../../components/rating/EmptyRating';

const { Title, Content } = DrawerLayout;

const RatingView = function () {
  return (
    <DrawerLayout>
      <Title>Rating</Title>
      <Content>
        <Card>
          <EmptyRating />
        </Card>
      </Content>
    </DrawerLayout>
  );
};

export default RatingView;
