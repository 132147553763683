import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Typography,
  Input,
  Button,
  Row,
  Col,
} from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { parse } from '@guuru/url-common';
import { error } from '@guuru/react-message';
import { signIn as firebaseSignIn } from '@guuru/auth-web';
import log from '@guuru/logger-web';
import { Link, useMatch } from 'react-router-dom';
import CreateAccount from '../CreateAccount';
import ErrorMessages from '../ErrorMessages';
import FormItem from '../FormItem';
import ForgotPassword from '../ForgotPassword';

const authErrorsI18n = {
  'auth/wrong-password':
    "Your password doesn't seem correct. Please try again.",
  'auth/user-not-found':
    "Your email doesn't seem correct. Please try again.",
  'auth/too-many-requests':
    'Your account is temporarily blocked. Please try again later.',
  'auth/access-denied':
    'You are not authorized to access.',
};

const { Paragraph, Text } = Typography;

const Login = function ({
  login = () => {},
  signInErrorCode = null,
  origin = null,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isForgotPassword = useMatch('/signin/forgot-password');
  const [submitError, setSubmitError] = useState(null);
  const errorMessage = authErrorsI18n[submitError || signInErrorCode]
    ?? 'Please try again later or contact Guuru Support';

  const urlLocation = parse(window.location.search);
  const isCreateAccount = !!urlLocation?.id;

  if (isCreateAccount) {
    return <CreateAccount />;
  }

  const signIn = async (email, password) => {
    setIsSubmitting(true);
    try {
      await firebaseSignIn(email, password);
      login();
      setIsSubmitting(false);
    } catch (err) {
      if (
        !['auth/wrong-password', 'auth/user-not-found'].includes(err.message)
      ) {
        log.error(`Error authenticating user: ${error.message}`);
      }
      setSubmitError(err.message);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (values) => {
    await signIn(values.email, values.password);
  };

  if (isForgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <>
      {(submitError || signInErrorCode)
        && <ErrorMessages message={errorMessage} />}
      <Paragraph>Sign in to your account</Paragraph>
      <Form
        layout="vertical"
        className="login-form"
        onFinish={handleSubmit}
      >
        <FormItem
          name="email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: 'Please type your email address',
            },
            {
              type: 'email',
              message: 'Please type a valid email address',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Your Email"
            autoComplete="username"
            autoFocus
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Your Password"
            autoComplete="current-password"
            prefix={
              <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }
          />
        </FormItem>
        <FormItem>
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Sign in
              </Button>
            </Col>
            <Col span={24} lg={12}>
              <Link to="forgot-password">
                <Button block>
                  Forgot password?
                </Button>
              </Link>
            </Col>
          </Row>
        </FormItem>
        {!origin && (
        <Text>
          Don&apos;t have a Guuru account? <Link to="/signup">Sign up now</Link>
        </Text>
        )}
      </Form>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  origin: PropTypes.string,
  signInErrorCode: PropTypes.string,
};

export default Login;
