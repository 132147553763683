import React from 'react';
import { PropTypes } from 'prop-types';
import ms from 'ms';
import { Typography } from 'antd';

const getNarrowDateTime = (timestamp, locale) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const relativeDay = new Date(timestamp).setHours(0, 0, 0, 0);
  const daysDifference = Math.abs(Math.ceil((relativeDay - today) / ms('1d')));

  switch (daysDifference) {
    case (0):
      // hh:mm
      return (
        new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric', hour12: false })
          .format(timestamp)
      );
    case (1):
      // yesterday
      return (
        new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(-1, 'day')
      );
    // weekday
    case (2): case (3): case (4): case (5): case (6):
      return (
        new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(timestamp)
      );
    default:
      // mm/dd/yyyy
      return new Intl.DateTimeFormat(locale).format(timestamp);
  }
};

const getCustomDateTime = (timestamp, locale, options) => (
  new Intl.DateTimeFormat(locale, options).format(timestamp)
);

const DateTime = function ({
  date: timestamp = new Date().getTime(),
  options = undefined,
  ...props
}) {
  const locale = 'en';

  const text = options
    ? getCustomDateTime(timestamp, locale, options)
    : getNarrowDateTime(timestamp, locale);

  return (
    <Typography.Text type="secondary" style={props}>{text}</Typography.Text>
  );
};

DateTime.propTypes = {
  date: PropTypes.number,
  options: PropTypes.shape({
  }),
};

export default React.memo(DateTime);
