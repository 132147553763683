import styled from 'styled-components';
import { Row } from 'antd';

const SubHeader = styled(Row)`
  background-color: ${({ theme }) => theme.primaryVariants[7]};
  padding: 3px 14px;
  justify-content: space-evenly;

  && {
    > button {
      flex-grow: 1;
      width: fit-content;
    }

    section {
      flex-grow: 2;
      display: flex;
      justify-content: space-evenly;
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export default SubHeader;
