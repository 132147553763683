import React from 'react';
import { SignUpBox } from '@guuru/react-login';
import AuthView from './AuthView';

const SignUpView = function () {
  return (
    <AuthView>
      <SignUpBox />
    </AuthView>
  );
};

export default SignUpView;
