import React from 'react';
import DrawerLayout from '../../containers/DrawerLayout';
import ConversationHistory from '../../components/ConversationHistory';

const { Title, Content } = DrawerLayout;

const HistoryView = function () {
  return (
    <DrawerLayout>
      <Title>Conversation History</Title>
      <Content>
        <ConversationHistory />
      </Content>
    </DrawerLayout>
  );
};

export default HistoryView;
