import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Avatar, Badge } from 'antd';

const Status = styled(Badge)`
  .ant-badge-dot {
    width: 10px;
    height: 10px;
  }
`;

const Picture = function ({
  photoUrl,
  isAvailable = true,
  isLoading = true,
}) {
  return isLoading
    ? <Avatar src={photoUrl} />
    : (
      <Status
        dot
        status={isAvailable ? 'success' : 'warning'}
        offset={[-5, 35]}
      >
        <Avatar src={photoUrl} />
      </Status>
    );
};

Picture.propTypes = {
  isAvailable: PropTypes.bool,
  isLoading: PropTypes.bool,
  photoUrl: PropTypes.string.isRequired,
};

export default Picture;
