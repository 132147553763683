import React, { useState, Suspense } from 'react';
import {
  Steps,
  Button,
  Typography,
  Row,
  Col,
  Form,
} from 'antd';
import { Loading } from '@guuru/react-loading';
import PropTypes from 'prop-types';
import ProfileSettings from '../settings/ProfileSettings';
import LanguageSettings from '../settings/LanguageSettings';
import AvailabilitySettings from '../settings/AvailabilitySettings';
import Intro from './Intro';
import Communities from './Communities';
import OnboardingModal from './OnboardingModal';

const steps = [
  {
    title: 'Intro',
    content: <Intro />,
  },
  {
    title: 'Account',
    content: <ProfileSettings onboarding />,
  },
  {
    title: 'Languages',
    content: <LanguageSettings />,
  },
  {
    title: 'Availability',
    content: <AvailabilitySettings />,
  },
  {
    title: 'Communities',
    content: <Communities />,
  },
];

const { Text } = Typography;

const Onboarding = function ({ onFinish = () => {} }) {
  const [open, setOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const showPrevButton = currentStep > 0;
  const showNextButton = currentStep < steps.length - 1;
  const showDoneButton = currentStep === steps.length - 1;
  const [isStepsLocked, setIsStepsLocked] = useState(true);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const close = () => {
    setOpen(false);
    onFinish();
  };

  return (
    <OnboardingModal
      title={(
        <Row gutter={[8, 16]}>
          <Text strong>Profile Tutorial</Text>
          <Col flex="1" align="center">
            <Steps
              type="inline"
              current={currentStep}
              items={currentStep > 0 ? items : []}
            />
          </Col>
        </Row>
      )}
      open={open}
      footer={[
        showPrevButton && (
          <Button key="previous" onClick={() => setCurrentStep(currentStep - 1)}>
            Previous
          </Button>
        ),
        showNextButton && (
          <Button
            key="next"
            type="primary"
            disabled={currentStep > 0 && isStepsLocked}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Next
          </Button>
        ),
        showDoneButton && (
          <Button key="done" type="primary" onClick={close}>
            Done
          </Button>
        ),
      ]}
      onCancel={() => setOpen(false)}
      width={500}
      maskClosable={false}
      closeIcon={false}
    >
      <Suspense fallback={<Loading />}>
        <Form.Provider
          onFormFinish={(name) => {
            if (name === 'profileForm') {
              setIsStepsLocked(false);
            }
          }}
        >
          {steps[currentStep].content}
        </Form.Provider>
      </Suspense>
    </OnboardingModal>
  );
};

Onboarding.propTypes = {
  onFinish: PropTypes.func,
};

export default Onboarding;
