import styled from 'styled-components';

const Headline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;

  .product-headline {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-title {
    font-size: 40px;
    font-weight: 200;

    strong {
      font-weight: 600;
    }
  }
`;

export default Headline;
