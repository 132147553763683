import {
  Card, Col, Drawer, Row, Space, theme, Typography,
} from 'antd';
import React, { Suspense, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Loading } from '@guuru/react-loading';
import useDrawers from '../../hooks/useDrawers';

const { useToken } = theme;
const Title = ({ children }) => children;
const Content = ({ children }) => children;

const DrawerLayout = function ({ children }) {
  const [visible, setVisible] = useState(true);
  const { close } = useDrawers();
  const { token } = useToken();

  const childrenArray = React.Children.toArray(children);
  // TODO(Diogo): Rename Title to Header and pass text (title) as title prop
  const header = childrenArray.find(({ type }) => type === Title);
  const content = childrenArray.find(({ type }) => type === Content);
  const title = header?.props?.children;
  const actions = header?.props?.actions;

  const onClose = () => {
    setVisible(false);
    setTimeout(close, 300); // wait for drawer animation to finish
  };

  return (
    <Drawer
      title={(
        <Row align="bottom">
          <Col flex="1">
            <Space size="large" align="end" style={{ height: '106px', padding: '8px 24px' }}>
              <Typography.Title level={4}>
                <ArrowLeftOutlined onClick={onClose} />
              </Typography.Title>
              <Typography.Title level={4} ellipsis={{ rows: 2 }}>
                {title}
              </Typography.Title>
            </Space>
          </Col>
          <Col style={{ padding: '8px 16px' }}>
            {actions}
          </Col>
        </Row>
      )}
      styles={{ header: { backgroundColor: token.colorPrimary } }}
      closable={false}
      placement="left"
      open={visible}
      onClose={onClose}
      mask={false}
      getContainer={false}
      width="100%"
    >
      <Suspense
        fallback={(
          <Card>
            <Loading />
          </Card>
        )}
      >
        {content || children}
      </Suspense>
    </Drawer>
  );
};

DrawerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

DrawerLayout.Title = Title;
DrawerLayout.Content = Content;

export default DrawerLayout;
