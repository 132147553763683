import React from 'react';
import { Card } from 'antd';
import DrawerLayout from '../../../containers/DrawerLayout';
import LanguageSettings from '../../../components/settings/LanguageSettings';

const { Title, Content } = DrawerLayout;

const LanguageSettingsView = function () {
  return (
    <DrawerLayout>
      <Title>Language</Title>
      <Content>
        <Card>
          <LanguageSettings />
        </Card>
      </Content>
    </DrawerLayout>
  );
};

export default LanguageSettingsView;
