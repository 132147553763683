import { gql } from '@apollo/client';

export default gql`
  query GetExpertPartnerCategoryQuizUrl($id: ID!, $partnerId: ID!, $category: String, $languageCode: String) {
    expert(id: $id) {
      id
      quizUrl(partnerId: $partnerId, category: $category, languageCode: $languageCode)
    }
  }
`;
