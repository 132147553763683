import React from 'react';
import { Card } from 'antd';
import DrawerLayout from '../../containers/DrawerLayout';
import PayoutsByMonth from '../../components/payouts';

const { Title, Content } = DrawerLayout;

const PayoutsView = function () {
  return (
    <DrawerLayout>
      <Title>Payouts</Title>
      <Content>
        <Card>
          <PayoutsByMonth />
        </Card>
      </Content>
    </DrawerLayout>
  );
};

export default PayoutsView;
