import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Button, Typography } from 'antd';
import ExpertCommunity from '../../../../../assets/images/ExpertCommunity.svg';
import Profile from '../../../../../assets/images/Profile.svg';
import KnowledgeBase from '../../../../../assets/images/KnowledgeBase.svg';
import useExpertPartners from '../../../../../hooks/useExpertPartners';
import useDrawers from '../../../../../hooks/useDrawers';
import ExpertProfileView from '../../../../../views/drawers/YouView';

const { Text } = Typography;

const handleOpenCommunityPage = (partnerId, utmSource) => {
  window.open(`${process.env.COMMUNITY_ENDPOINT}/${partnerId}/guurus/?utm_source=${utmSource}`);
};

const handleOpenKnowledgeBase = (partnerId, languageCode) => {
  window.open(`${process.env.PAGES_URL}/${partnerId}/articles?locale=${languageCode}`);
};

const CommunityButtons = function ({ partner }) {
  const { locale } = useExpertPartners();
  const { open } = useDrawers();

  const { node: { id: partnerId }, bucket } = partner;

  const showProfile = ['active', 'onboarding', 'inactive'].includes(bucket);

  const communityButtons = showProfile ? [
    {
      name: 'Members',
      icon: <ExpertCommunity />,
      onClick: () => { handleOpenCommunityPage(partnerId, 'web_community_view'); },
    },
    {
      name: 'You',
      icon: <Profile />,
      onClick: () => open(ExpertProfileView, { partner }),
    },
    {
      name: 'Knowledge',
      icon: <KnowledgeBase />,
      onClick: () => { handleOpenKnowledgeBase(partnerId, locale); },
    },
  ] : [
    {
      name: 'Members',
      icon: <ExpertCommunity />,
      onClick: () => { handleOpenCommunityPage(partnerId, 'web_community_view'); },
    },
    {
      name: 'Knowledge',
      icon: <KnowledgeBase />,
      onClick: () => { handleOpenKnowledgeBase(partnerId, locale); },
    },
  ];

  return (
    <Row justify="center">
      {communityButtons.map((button) => (
        <Button
          key={button.name}
          type="text"
          icon={button.icon}
          onClick={button.onClick}
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Text>{button.name}</Text>
        </Button>
      ))}
    </Row>
  );
};

CommunityButtons.propTypes = {
  partner: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
    }),
    bucket: PropTypes.string,
  }).isRequired,
};

export default CommunityButtons;
