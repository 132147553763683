import { gql } from '@apollo/client';

export default gql`
  query GetExpertPartnersNews($id: ID!, $after: String) {
    expert(id: $id) {
      id
      newsReadAt
      news(first: 5, after: $after) {
        edges{
          cursor
          node {
            id
            partner {
              id
              name
              settings {
                id
                appearance {
                  chatWidgetPhotoUrl
                }
              }
            }
            title
            message
            createdAt
            updatedAt
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
