import { gql } from '@apollo/client';

export default gql`
  mutation SwitchExpertLanguage($expertId: ID!, $language: String!, $value: Boolean!) {
    switchLanguage(expertId: $expertId, language: $language, value: $value) {
      id
      languages
    }
}
`;
