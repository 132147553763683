import { gql } from '@apollo/client';

export default gql`
  query GetExpertAvailability($id: ID!) {
    expert(id: $id) {
      id
      isAvailable
      availability {
        status
        timezone
        schedule {
          weekday
          start
          end
        }
      }
    }
  }
`;
