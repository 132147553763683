import { gql } from '@apollo/client';

export default gql`
  mutation UpdateExpertPhoto($id: ID!, $photoUrl: String) {
    updatePhotoUrl(id: $id, photoUrl: $photoUrl) {
      id
      photoUrl
    }
  }
`;
