import { gql } from '@apollo/client';

export default gql`
  query GetExpertPayouts($id: ID!, $first: Int) {
    expert(id: $id) {
      id
      allStatements(first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            period
            statements {
              product
              balances {
                amount
                currency
              }
              status
            }
          }
        }
      }
    }
  }
`;
