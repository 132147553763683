import React from 'react';
import { PropTypes } from 'prop-types';
import { useElapsedTime } from '@guuru/react-hooks';
import RelativeTime from '../../../RelativeTime';

const ElapsedTime = function ({ date }) {
  const { value, unit } = useElapsedTime(date);
  const warn = (unit !== 'second' && value > 3) || unit === 'hour' || undefined;

  return (
    <RelativeTime
      value={-value}
      unit={unit}
      fontSize={12}
      lineHeight={1}
      type={warn && 'danger'}
    />
  );
};

ElapsedTime.propTypes = {
  date: PropTypes.number.isRequired,
};

export default ElapsedTime;
