import { useSuspenseTranslations } from '@guuru/react-hooks';
import React from 'react';
import Empty from '../Empty';
import AboutGuuruRating from '../../assets/images/AboutGuuruRating.svg';

const EmptyRating = function () {
  const {
    data: { ratingHeader, ratingDescription } = {},
  } = useSuspenseTranslations(['ratingHeader', 'ratingDescription']);

  return (
    <Empty
      image={<AboutGuuruRating />}
      title={ratingHeader}
      description={ratingDescription}
    />
  );
};

export default EmptyRating;
