import { gql } from '@apollo/client';
import INBOX_CHAT from '../fragments/inboxChat';

export default gql`
  ${INBOX_CHAT}
  subscription InboxAddChat($id: ID!) {
    inboxAddChat(id: $id) {
      ...InboxChat
    }
  }
`;
