import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  Row,
} from 'antd';

const {
  Paragraph,
  Link,
  Text,
  Title,
} = Typography;

const RecoverEmailMessage = function ({ hide }) {
  return (
    <>
      <Divider />
      <Title level={2}>Check your email</Title>
      <Paragraph>
        We have sent an email to the address you provided.
      </Paragraph>
      <Paragraph>
        <Row>
          <Text strong>
            Click the link in the email to reset your password.
          </Text>
        </Row>
        <Row>
          <Text>
            The link will be valid for <Text strong>15 minutes.</Text>
          </Text>
        </Row>
      </Paragraph>
      <Paragraph>
        If you don&apos;t see the email, check other places it might be,
        like your junk, spam, or other folders.
      </Paragraph>
      <Link to="/sigin/forgot-password" onClick={hide}>
        I didn&apos;t receive the email
      </Link>
    </>
  );
};

RecoverEmailMessage.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default RecoverEmailMessage;
