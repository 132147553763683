import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import NamedLogo from '../NamedLogo';
import Headline from './Headline';

const ProductName = function ({ name }) {
  return (
    <Row type="flex" justify="center">
      <Col span={24} sm={12} xl={8}>
        <Headline>
          <div className="product-headline">
            <span className="product-title">
              <strong>{name}</strong>
            </span>
            <NamedLogo />
          </div>
        </Headline>
      </Col>
    </Row>
  );
};

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProductName;
