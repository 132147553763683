import { gql } from '@apollo/client';

export default gql`
  mutation UpdateExpertLocale($id: ID!, $locale: String!) {
    updateLocale(id: $id, locale: $locale) {
      id
      locale
    }
  }
`;
