import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { ProductName } from '@guuru/react-named-logo';
import { RedirectOnLoggedIn } from '@guuru/react-routing';
import useCurrentUser from '../hooks/useCurrentUser';

const AuthView = function ({ children }) {
  const user = useCurrentUser();

  return (
    <RedirectOnLoggedIn session={user} redirectToPath="/">
      <ProductName name="Expert" />
      <Row justify="center" align="middle">
        <Col span={24} xs={18} sm={8} xl={7}>
          {children}
        </Col>
      </Row>
    </RedirectOnLoggedIn>
  );
};

AuthView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthView;
