import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { error } from '@guuru/react-message';
import {
  Col, Row, Spin, Typography,
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import VERIFY_EMAIL from './mutations/verifyEmail';

const { Paragraph } = Typography;

const VerifyEmail = function () {
  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL);
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const token = searchParams.get('id');
    const verify = async () => {
      try {
        await verifyEmail({ variables: { token } });
        setIsVerified(true);
      } catch (e) {
        error(e);
      }
    };
    if (token) {
      verify();
    } else {
      error('Invalid token');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col type="flex" align="middle">
      <Paragraph>
        {isVerified ? 'Your Email has been verified!' : 'Verifying your email...'}
      </Paragraph>
      { loading && (
        <Row justify="center">
          <Spin />
        </Row>
      )}
    </Col>
  );
};

export default VerifyEmail;
