import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerStackProvider } from '../context/drawerStackContext';

const Drawers = ({ stack }) => (
  stack.map(([DrawerView, props], index) => (
    // eslint-disable-next-line react/no-array-index-key
    <DrawerView key={index} {...props} />
  ))
);

const DrawerStack = function ({ children }) {
  const [stack, setStack] = useState([]);

  const value = useMemo(() => {
    const pop = (amount = 1) => (
      setStack((prev) => [...prev].slice(0, prev.length - amount))
    );

    const push = (component, props) => (
      setStack((prev) => [...prev, [component, props]])
    );

    const dismissAll = () => setStack([]);

    const dismiss = (amount) => {
      if (stack.length === 1) {
        dismissAll();
      } else {
        pop(amount);
      }
    };

    return {
      open: push,
      close: () => dismiss(1),
      closeAll: dismissAll,
    };
  }, [stack]);

  return (
    <DrawerStackProvider value={value}>
      {children}
      <Drawers stack={stack} />
    </DrawerStackProvider>
  );
};

DrawerStack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DrawerStack;
