import React from 'react';
import { chatType } from '@guuru/react-types';
import { theme } from 'antd';
import PropTypes from 'prop-types';
import { ChatState } from '@guuru/constants-common';
import ElapsedTime from './ElapsedTime';
import DateTime from '../../../DateTime';

const { open, assigned, created } = ChatState;

const { useToken } = theme;

const Time = function ({ chat, highlighted = false }) {
  const { token } = useToken();
  const {
    status,
    createdAt,
    ratedAt,
    lastMessage,
  } = chat;

  if ([assigned, created].includes(status) && createdAt) {
    return <ElapsedTime date={createdAt} />;
  }

  const eventTimestamp = (
    status === open ? lastMessage?.createdAt : (ratedAt || createdAt)
  );

  return (
    <DateTime
      date={eventTimestamp}
      fontSize={12}
      lineHeight={1}
      color={highlighted && token.colorPrimary}
      fontWeight={highlighted && token.fontWeightStrong}
    />
  );
};

Time.propTypes = {
  chat: chatType.isRequired,
  highlighted: PropTypes.bool,
};

export default Time;
