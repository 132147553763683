import React from 'react';
import { Space } from 'antd';
import { GuuruOutlined } from '@guuru/react-icon';

const NamedLogo = function () {
  return (
    <Space size={4}>
      <GuuruOutlined alt="Guuru Logo" />
      Guuru
    </Space>
  );
};

export default NamedLogo;
