import { gql } from '@apollo/client';

export default gql`
  subscription NewsByExpertUpdated {
    newsByExpertUpdated {
      id
      title
      message
      createdAt
      updatedAt
    }
  }
`;
