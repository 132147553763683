import { Card, Space } from 'antd';
import React from 'react';
import { PropTypes } from 'prop-types';
import PendingCard from './PendingCard';
import AboutForm from './AboutForm';
import ChatsEnabler from './ChatsEnabler';
import ExpertiseCard from './ExpertiseCard';
import InactivityCard from './InactivityCard';

const JoinedCards = function ({ partner }) {
  const {
    bio,
    bucket,
    roles,
    isEnabled,
    categories,
    node: {
      name,
      id: partnerId,
    } = {},
  } = partner;

  return (
    <>
      {!['inactive', 'pending'].includes(bucket)
        && (
          <>
            <Card>
              <Space direction="vertical" style={{ width: '100%' }} size={18}>
                <ChatsEnabler
                  partnerId={partnerId}
                  name={name.en}
                  isEnabled={isEnabled}
                />
                <AboutForm partnerId={partnerId} name={name.en} bio={bio} />
              </Space>
            </Card>
            <ExpertiseCard
              categories={categories}
              partnerId={partnerId}
              isEnabled={isEnabled}
            />
          </>
        )}
      {bucket === 'inactive' && (
        <InactivityCard partnerId={partnerId} name={name.en} roles={roles} />
      )}
      {bucket === 'pending' && (
        <PendingCard partnerId={partnerId} />
      )}
    </>
  );
};

JoinedCards.propTypes = {
  partner: PropTypes.shape({
    bio: PropTypes.string,
    bucket: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    isEnabled: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    node: PropTypes.shape({
      name: PropTypes.shape({
        en: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default JoinedCards;
