import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Alert,
  Col,
  Row,
} from 'antd';
import { signIn } from '@guuru/auth-web';
import { Form, Field, useForm } from '@guuru/react-forms';
import UPDATE_PASSWORD from './mutations/updatePassword';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const UpdatePassword = function () {
  const user = useCurrentUser();
  const [passwordForm] = useForm();
  const [, forceUpdate] = useState();
  useEffect(() => { forceUpdate({}); }, []);

  const [
    updateExpertPassword,
    { loading: isUpdating },
  ] = useMutation(UPDATE_PASSWORD);

  const handlePasswordUpdate = async ({ newPassword }) => {
    const variables = {
      id: user.uid,
      input: {
        password: newPassword,
      },
    };
    await updateExpertPassword({ variables });
    passwordForm.resetFields();
    await signIn(user.email, newPassword);
  };

  const validatePassword = async function (rule, value) {
    try {
      await signIn(user.email, value);
    } catch (err) {
      return Promise.reject(new Error('Current password is incorrect'));
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          message="Your new password will be set and take effect immediately "
          type="warning"
          showIcon
        />
      </Col>
      <Col span={24}>
        <Form
          form={passwordForm}
          onSave={handlePasswordUpdate}
          isSaving={isUpdating}
          savingMessage="Updating Password..."
          savedMessage="Password updated"
          saveLabel="Update Password"
        >
          <Field
            label="Current password"
            name="currentPassword"
            validateTrigger="onSubmit"
            rules={[{
              required: true,
              message: 'Please input your current password!',
            },
            { validator: validatePassword }]}
          >
            <Input.Password autoComplete="on" type="password" />
          </Field>
          <Field
            label="New password"
            name="newPassword"
            dependencies={['currentPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              {
                min: 6,
                message: 'Must be at least 6 characters',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  return (!value || getFieldValue('currentPassword') !== value)
                    ? Promise.resolve()
                    : Promise.reject(new Error(
                      'New password cannot be the same as current password',
                    ));
                },
              }),
            ]}
          >
            <Input.Password autoComplete="on" type="password" />
          </Field>
          <Field
            label="Confirm password"
            name="newPasswordConfirm"
            hasFeedback
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
              {
                min: 6,
                message: 'Must be at least 6 characters',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  return (!value || getFieldValue('newPassword') === value)
                    ? Promise.resolve()
                    : Promise.reject(new Error(
                      'The two passwords that you entered do not match!',
                    ));
                },
              }),
            ]}
          >
            <Input.Password autoComplete="on" type="password" />
          </Field>
        </Form>
      </Col>
    </Row>
  );
};

export default UpdatePassword;
