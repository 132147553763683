import PropTypes from 'prop-types';
import { printBalances } from '@guuru/money-common';

const Balance = function ({ balances = [] }) {
  return printBalances(balances);
};

Balance.propTypes = {
  balances: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Balance;
