import React from 'react';
import {
  Col,
  Divider,
  Row,
} from 'antd';
import PageFooter from './PageFooter';

const Footer = function (props) {
  const year = new Date().getFullYear();
  return (
    <PageFooter {...props}>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col>
          &copy; {`${year} `}
          <a
            href="https://www.guuru.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Guuru
          </a>
          <Divider type="vertical" />
          Connect your customers
        </Col>

        <Col>
          <a
            href="https://www.guuru.com/en/terms-of-service"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>

          <Divider type="vertical" />

          <a
            href="https://www.guuru.com/en/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </Col>
      </Row>
    </PageFooter>
  );
};

export default Footer;
