import { gql } from '@apollo/client';

export default gql`
  subscription NewsByExpertCreated {
    newsByExpertCreated {
      cursor
      node {
        id
        title
        message
        createdAt
        updatedAt
        partner {
          id
          name
          settings {
            id
            appearance {
              chatWidgetPhotoUrl
            }
          }
        }
      }
    }
  }
`;
