import React, { Suspense, lazy } from 'react';
import { PageNotFound } from '@guuru/react-error-pages';
import LoadingView from '@guuru/react-loading';
import { Route, Routes } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import ExpertLayout from '../containers/ExpertLayout';
import BasicLayout from '../containers/BasicLayout';
import SignUpView from './SignUpView';

const Chat = lazy(() => import('./Chat'));
const LoginView = lazy(() => import('./LoginView'));
const RecoverView = lazy(() => import('./RecoverView'));
const VerifyEmailView = lazy(() => import('./VerifyEmailView'));
const VerifyView = lazy(() => import('./VerifyView'));
const AcceptTos = lazy(() => import('./AcceptTos'));

const loading = <LoadingView />;

const MainView = function () {
  const { loading: inAuthentication } = useAuth();

  if (inAuthentication) {
    return loading;
  }

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route element={<ExpertLayout />}>
          {['/', '/chats', '/chats/:uid'].map((path) => (
            <Route key={path} path={path} element={<Chat />} />
          ))}
        </Route>

        <Route element={<BasicLayout />}>
          <Route path="/signin/forgot-password?" element={<LoginView />} />
          <Route path="/signup" element={<SignUpView />} />
          <Route path="/recoveraccount" element={<RecoverView />} />
          <Route path="/verifyemail" element={<VerifyEmailView />} />
          <Route path="/verify" element={<VerifyView />} />
          <Route path="/acceptTos" element={<AcceptTos />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainView;
