import { PropTypes } from 'prop-types';
import React from 'react';
import { Typography } from 'antd';
import useCurrentUser from '../hooks/useCurrentUser';

const RelativeTime = function ({
  value,
  unit,
  options = { style: 'short' },
  type = 'secondary',
  ...props
}) {
  const { locale } = useCurrentUser();
  const text = new Intl.RelativeTimeFormat(locale, options)
    .format(value, unit);
  return (
    <Typography.Text type={type} style={props}>{text}</Typography.Text>
  );
};

RelativeTime.propTypes = {
  value: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  type: PropTypes.string,
  options: PropTypes.shape({
  }),
};

export default React.memo(RelativeTime);
