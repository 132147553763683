import React from 'react';
import { Typography } from 'antd';

const { Link } = Typography;

const TermsOfServiceContent = function () {
  return (
    <>
      {' I agree to the Guuru '}
      <Link
        target="_blank"
        href="https://www.guuru.com/en/terms-of-service/"
      >
        Terms and Conditions
      </Link>
      {' and '}
      <Link
        target="_blank"
        href="https://www.guuru.com/en/privacy-policy/"
      >
        Privacy Policy
      </Link>
    </>
  );
};

export default TermsOfServiceContent;
