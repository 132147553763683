import React from 'react';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import useDrawers from '../../hooks/useDrawers';
import IconButton from '../../components/IconButton';
import DrawerLayout from '../../containers/DrawerLayout';
import OpenCommunitiesView from './OpenCommunitiesView';
import JoinedCommunitiesList from '../../components/header/communities/JoinedCommunitiesList';

const { Title, Content } = DrawerLayout;

const JoinedCommunitiesView = function () {
  const { open } = useDrawers();

  return (
    <DrawerLayout>
      <Title
        actions={(
          <IconButton
            onClick={() => open(OpenCommunitiesView)}
            icon={<PlusOutlined />}
          />
        )}
      >
        Communities
      </Title>
      <Content>
        <JoinedCommunitiesList />
      </Content>
    </DrawerLayout>
  );
};

export default JoinedCommunitiesView;
