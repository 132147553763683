import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { parse } from '@guuru/url-common';
import { error } from '@guuru/react-message';
import {
  Button,
  Form,
  Input,
  Col,
  Typography,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import FormItem from '../FormItem';
import RECOVER_ACCOUNT_PASSWORD from './mutations/recoverAccount';

const RecoverAccount = function () {
  const [form] = Form.useForm();
  const [isAnswered, setIsAnswered] = useState(false);
  const [recoverPassword, { loading }] = useMutation(RECOVER_ACCOUNT_PASSWORD);

  const handleSubmit = async (values) => {
    const urlLocation = parse(window.location.search);
    const variables = {
      input: {
        token: urlLocation?.id || '',
        password: values.password,
      },
    };

    try {
      const {
        data: {
          resetExpertPassword,
        },
      } = await recoverPassword({ variables });

      if (resetExpertPassword) {
        setIsAnswered(true);
      } else {
        error();
      }
    } catch (e) {
      error(e);
    }
  };

  if (isAnswered) {
    return (
      <Typography>
        <br />
        <Col type="flex" align="middle">
          <p>Your password has been reset successfully!</p>
          <Button href="../signin" type="ghost">
            Click here to sign in
          </Button>
        </Col>
      </Typography>
    );
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="ant-form-vertical"
    >
      <FormItem
        required
        name="password"
        label="Please enter your new password"
        validateTrigger="onSubmit"
        initialValue=""
        rules={[
          {
            required: true,
            message: 'Your new password must have at least 6 characters.',
            min: 6,
          },
        ]}
      >
        <Input.Password
          autoFocus
          size="large"
          placeholder="New Password"
          autoComplete="new-password"
          prefix={
            <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          }
        />
      </FormItem>
      <Col type="flex" align="right">
        <Form.Item shouldUpdate>
          {() => (
            <Button
              onClick={form.submit}
              disabled={!form.isFieldsTouched() || loading}
              loading={loading}
            > Reset password
            </Button>
          )}
        </Form.Item>
      </Col>
    </Form>
  );
};

export default RecoverAccount;
