import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Flex } from 'antd';
import { useSuspenseQuery } from '@apollo/client';
import GET_PARTNER_LOGO from './queries/getPartnerLogoUrl';

const avatarSizes = {
  xs: '30%',
  sm: '15%',
  md: '15%',
  lg: '15%',
  xl: '10%',
  xxl: '10%',
};

const PartnerLogo = function ({ id }) {
  const {
    data: {
      partner: {
        settings: {
          appearance: {
            chatWidgetPhotoUrl,
          } = {},
        } = {},
      } = {},
    } = {},
  } = useSuspenseQuery(GET_PARTNER_LOGO, {
    variables: { id },
  });

  return (
    <Flex flex="auto" justify="center">
      <Avatar src={chatWidgetPhotoUrl} size={avatarSizes} alt="partner logo" />
    </Flex>
  );
};

PartnerLogo.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PartnerLogo;
