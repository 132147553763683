import { gql } from '@apollo/client';

export default gql`
  mutation ChangeExpertStatus($id: ID!, $status: ExpertAvailabilityStatus!) {
    updateAvailability(id: $id, availability: { status: $status }) {
      id
      isAvailable
    }
  }
`;
