import { useSubscription, useSuspenseQuery } from '@apollo/client';
import useCurrentUser from '../useCurrentUser';
import VISIBLE_PARTNERS from './queries/getActiveVisiblePartners';
import EXPERT_PARTNERS_UPDATE from '../subscriptions/expertPartnersUpdate';

export default function usePartnersListOpen() {
  const { id: expertId } = useCurrentUser();

  const {
    loading,
    refetch,
    fetchMore,
    data: {
      partners: {
        pageInfo,
        edges: partners = [],
      } = {},
    } = {},
  } = useSuspenseQuery(VISIBLE_PARTNERS, {
    variables: {
      filters: [{ key: 'visibleRecruitment', value: { expertId } }],
      first: 10,
      after: null,
    },
    fetchPolicy: 'network-only',
  });

  useSubscription(EXPERT_PARTNERS_UPDATE, {
    variables: { id: expertId },
    onData: () => refetch(),
  });

  return {
    loading,
    partners,
    fetchMore,
    pageInfo,
    refetch,
  };
}
