import { gql } from '@apollo/client';
import expertPartnersFragment from '../../../../../../../../graphql/fragments/expertPartnersFragment';

export default gql`
    ${expertPartnersFragment}
  mutation ReactivateExpertAccount($id: ID!, $partnerId: ID!) {
    reActivateExpert(id: $id, partnerId: $partnerId) {
      id
      ...ExpertPartners
    }
  }
`;
