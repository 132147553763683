import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Typography } from 'antd';
import { useSuspenseTranslations } from '@guuru/react-hooks';
import { MarkdownRender } from '@guuru/react-markdown-render';

const { Title, Text } = Typography;

const PendingCard = function ({ partnerId }) {
  const {
    data: { quizStatusPendingTitle = '', quizStatusPendingMessage = '' } = {},
  } = useSuspenseTranslations(
    ['quizStatusPendingTitle', 'quizStatusPendingMessage'],
    'en',
    { partner: partnerId },
  );
  return (
    <Card>
      <Row justify="center">
        <Title level={5} style={{ color: 'black' }}>
          {quizStatusPendingTitle}
        </Title>
        <Text type="secondary">
          <MarkdownRender content={quizStatusPendingMessage} />
        </Text>
      </Row>
    </Card>
  );
};

PendingCard.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default PendingCard;
