import { gql } from '@apollo/client';

export default gql`
  mutation MarkNewsRead($id: ID!) {
    markNewsRead(id: $id) {
      id
      newsReadAt
    }
  }
`;
