import React from 'react';
import {
  Col,
  Space,
  Typography,
  theme, Row,
} from 'antd';
import HowToSkills from '../../../assets/images/HowtoSkills.svg';
import HowToAnswer from '../../../assets/images/HowtoAnswer.svg';
import HowToStars from '../../../assets/images/HowtoStars.svg';
import CarouselWrapper from './CarouselWrapper';

const steps = [
  {
    title: 'Step 1: Select community in one or several areas',
    description: 'You can choose up to 3',
    step: <HowToSkills width={45} height={60} />,
  },
  {
    title: 'Step 2: Answer questions via live chat',
    step: <HowToAnswer width={90} height={65} />,
  },
  {
    title: 'Step 3: Get reward for great answers',
    step: <HowToStars width={85} height={60} />,
  },
];

const { Title, Text } = Typography;
const { useToken } = theme;

const Intro = function () {
  const { token } = useToken();
  return (
    <Col align="center">
      <Title level={4}>Intro</Title>
      <CarouselWrapper autoplay $colorPrimary={token.colorPrimary}>
        {steps.map(({ title, description, step }) => (
          <Row key={title}>
            <Col>
              <Text>{title}</Text>
            </Col>
            <Space direction="vertical" size="small">
              <Text type="secondary">{description}</Text>
              {step}
            </Space>
          </Row>
        ))}
      </CarouselWrapper>
    </Col>
  );
};

export default Intro;
