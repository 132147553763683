import { gql } from '@apollo/client';

export default gql`
  mutation UpdateExpertAvailability(
    $id: ID!,
    $availability: ExpertAvailabilityInput!,
  ) {
    updateAvailability(id: $id, availability: $availability) {
      id
      isAvailable
      availability {
        status
        timezone
        schedule {
          weekday
          start
          end
        }
      }
    }
  }
`;
