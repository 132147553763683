import React, { useRef } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { PropTypes } from 'prop-types';
import { App as AntContext, Button } from 'antd';
import { error } from '@guuru/react-message';
import JOIN_PARTNER from './mutations/joinPartner';
import WebViewer from '../../../../../WebViewer';
import QUIZ_RESOLVED from '../../subscriptions/quizResolved';

const JoinButton = function ({ partnerId }) {
  const { modal } = AntContext.useApp();
  const quizRef = useRef();
  const [JoinPartner, { loading }] = useMutation(JOIN_PARTNER, {
    variables: { partnerId, locale: 'en' },
  });

  useSubscription(QUIZ_RESOLVED, {
    onData: ({ data: { data } }) => {
      const { quizzResolved } = data;
      if (quizzResolved) {
        setTimeout(() => {
          quizRef.current?.close();
        }, 7500);
      }
    },
  });

  const handleJoinPartner = async () => {
    try {
      const {
        data: {
          joinPartner: { success, redirectUrl } = {},
        } = {},
      } = await JoinPartner();

      if (!success) {
        const quiz = modal.confirm({
          mask: false,
          // eslint-disable-next-line react/no-unstable-nested-components
          modalRender: () => (
            <WebViewer
              open
              ref={quizRef}
              source={redirectUrl}
              onCancel={() => quiz.destroy()}
            />
          ),
        });
      }
    } catch (err) {
      error(err.message);
    }
  };

  return (
    <Button
      onClick={handleJoinPartner}
      loading={loading}
      size="middle"
      type="primary"
    >
      Join Community
    </Button>
  );
};

JoinButton.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default JoinButton;
