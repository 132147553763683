import React from 'react';
import { chatType } from '@guuru/react-types';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

const FeedbackThumb = function ({ chat }) {
  const {
    rating,
    isAutoRated,
    status,
    feedback,
  } = chat;

  if (status !== 'rated' || isAutoRated) {
    return null;
  }

  const hasFeedback = feedback?.comment || feedback?.topics?.length;
  if (!hasFeedback) {
    return null;
  }

  return rating === 'good'
    ? <LikeOutlined style={{ color: 'green' }} />
    : <DislikeOutlined />;
};

FeedbackThumb.propTypes = {
  chat: chatType.isRequired,
};

export default FeedbackThumb;
