import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { Collapse, Empty as AntEmpty, Typography } from 'antd';
import moment from 'moment';
import GET_EXPERT_PAYOUTS from './queries/expertPayouts';
import Payout from './payout';
import useCurrentUser from '../../hooks/useCurrentUser';
import Empty from '../Empty';

const Payouts = function () {
  const { id: expertId } = useCurrentUser();

  const {
    data: {
      expert = {},
    } = {},
  } = useSuspenseQuery(GET_EXPERT_PAYOUTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: expertId,
      first: 100,
    },
  });

  if (!expert?.allStatements?.edges.length) {
    return (
      <Empty
        description="Seems you have not received any payment yet"
        image={AntEmpty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  return (
    <Collapse
      defaultActiveKey={['0']}
      items={expert?.allStatements?.edges?.map((statement, index) => ({
        label: (
          <Typography.Text type="secondary">
            {moment(statement?.node?.period).utc().format('MMMM YYYY')}
          </Typography.Text>
        ),
        extra:
            moment(statement?.node?.period).isSame(moment(), 'month')
              ? 'Current Active Payout'
              : null,
        key: index.toString(),
        children: (
          <Payout
            key={statement.cursor}
            statement={statement}
            expertId={expertId}
            collapseStyle={{ marginLeft: '15px' }}
          />
        ),
      }))}
    />
  );
};

export default Payouts;
