import { gql } from '@apollo/client';
import expertPartnersFragment
  from '../../../graphql/fragments/expertPartnersFragment';

export default gql`
  ${expertPartnersFragment}
  query GetExpertPartners($id: ID!) {
    expert(id: $id) {
      id
      ...ExpertPartners
    }
  }
`;
