import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Result,
  Checkbox,
  Button,
  Space,
} from 'antd';
import { FileProtectOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@guuru/auth-web';

import TermsOfServiceContent from '../TermsOfServiceContent';
import GET_EXPERT_ACCOUNT from './queries/getExpertAccount';
import AGREE_TO_TERMS from './mutations/agreeToTerms';

const AcceptTos = function ({ expertId }) {
  const [acceptTerms, setAcceptTerms] = useState(false);

  const navigate = useNavigate();

  const {
    data: {
      expert: {
        account = {},
      } = {},
    } = {},
  } = useQuery(GET_EXPERT_ACCOUNT, {
    variables: {
      id: expertId,
    },
  });

  const [agreeToTerms, { loading: isSaving }] = useMutation(AGREE_TO_TERMS);

  const submitTerms = async () => {
    await agreeToTerms({ variables: { id: account.id } });
    navigate('../');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('../');
  };

  return (
    <Row justify="center">
      <Result
        icon={<FileProtectOutlined />}
        title="Terms and Conditions"
        subTitle={(
          <p>Our Terms and Conditions have been updated.
            <br />Please review before continuing using our services
          </p>
          )}
      >
        <div className="desc">
          <Space direction="vertical">
            <Row>
              <Checkbox onChange={(e) => setAcceptTerms(e.target.checked)}>
                <TermsOfServiceContent />
              </Checkbox>
            </Row>
            <Row justify="center">
              <Space>
                <Button
                  type="default"
                  onClick={handleSignOut}
                >
                  Decline and Signout
                </Button>
                <Button
                  type="primary"
                  disabled={!acceptTerms}
                  onClick={submitTerms}
                  loading={isSaving}
                >
                  Agree
                </Button>
              </Space>
            </Row>
          </Space>
        </div>
      </Result>
    </Row>
  );
};

AcceptTos.propTypes = {
  expertId: PropTypes.string.isRequired,
};

export default AcceptTos;
