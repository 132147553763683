import React from 'react';
import { chatType } from '@guuru/react-types';
import { useSoundEffect } from '@guuru/chat-web';
import { useParams } from 'react-router-dom';
import { TimerHelper } from '@guuru/helpers-common';
import { Avatar, App as AntContext } from 'antd';
import ItemContent from './ItemContent';
import Pressable from '../../../Pressable';
import ListItem from '../../../ListItem';
import PaddingX from '../../../PaddingX';

const ChatItem = function ({ chat }) {
  const { uid: chatId } = useParams();
  const { notification } = AntContext.useApp();
  const {
    id,
    topic,
    partner,
    lastMessage,
    ratedAt,
    createdAt,
    lastSeenByExpertAt,
  } = chat;

  const seconds = TimerHelper.getTimeStopSinceTimestamp(createdAt);

  if (seconds < 0) {
    notification.warning({
      message: 'Your date is inaccurate',
      description: 'The time elapsed upon receiving a chat is wrong. '
        + 'Please adjust your clock',
    });
  }

  const {
    author: {
      __typename: lastMessageAuthor,
    } = {},
    createdAt: lastMessageAt,
  } = lastMessage || {};

  const isActiveChat = chatId === id;
  const isMessageFromUser = lastMessageAuthor === 'UserAuthor';
  const hasNewMessages = lastMessageAt > lastSeenByExpertAt;
  const hasUnreadMessages = (
    isMessageFromUser && !isActiveChat && hasNewMessages
  );
  const hasUnreadFeedback = ratedAt > lastSeenByExpertAt;

  useSoundEffect({ when: hasUnreadMessages }, [chat]);

  return (
    <Pressable $selected={isActiveChat} key={`chat-${id}`} to={`/chats/${id}`}>
      <ListItem
        avatar={(
          <PaddingX left size="middle">
            <Avatar
              size="large"
              alt={topic}
              src={partner.settings.appearance.chatWidgetPhotoUrl}
            />
          </PaddingX>
        )}
      >
        <ItemContent
          chat={chat}
          highlighted={hasUnreadMessages || hasUnreadFeedback}
        />
      </ListItem>
    </Pressable>
  );
};

ChatItem.propTypes = {
  chat: chatType.isRequired,
};

export default ChatItem;
