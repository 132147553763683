import { gql } from '@apollo/client';

export default gql`
  mutation AgreeToTerms($id: ID!) {
    agreeToTerms(id: $id) {
      id
      tosAcceptedAt
    }
  }
`;
