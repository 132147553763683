import { gql } from '@apollo/client';

export default gql`
  mutation UpdateExpertEmail($token: String!, $newEmail: String!, $inputCode: String!) {
    updateExpertEmail(token: $token, newEmail: $newEmail, inputCode: $inputCode) {
      id
      email
    }
  }
`;
