import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Row, Typography, Switch } from 'antd';
import { error, success } from '@guuru/react-message';
import SWITCH_EXPERTISE from './mutations/switchExpertise';
import ListItem from '../../../../../../ListItem';

const { Text } = Typography;

const ExpertiseSwitchItem = function ({
  partnerId,
  communityEnabled,
  expertise,
}) {
  const [isEnabledState, setIsEnabledState] = useState(expertise.isEnabled);
  const expertiseName = expertise.node.name.en;
  const [switchExpertise] = useMutation(SWITCH_EXPERTISE);

  const handleSwitchExpertise = async (value) => {
    setIsEnabledState(value);
    await switchExpertise({
      variables: {
        categoryId: expertise.node.id,
        partnerId,
        membership: {
          isEnabled: value,
        },
      },
    }).catch((er) => error(er.message));
    success(`You ${value ? 'enabled' : 'disabled'} the ${expertiseName} expertise area`);
  };

  return (
    <ListItem>
      <Row justify="space-between">
        <Text>{expertiseName}</Text>
        <Switch
          disabled={!communityEnabled}
          checked={isEnabledState}
          onChange={
            (value) => handleSwitchExpertise(value, expertise)
          }
        />
      </Row>
    </ListItem>
  );
};

ExpertiseSwitchItem.propTypes = {
  partnerId: PropTypes.string.isRequired,
  expertise: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.shape({
        en: PropTypes.string,
      }),
    }),
    isEnabled: PropTypes.bool,
  }).isRequired,
  communityEnabled: PropTypes.bool.isRequired,
};

export default ExpertiseSwitchItem;
