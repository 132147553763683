import React, { useMemo } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { Badge } from 'antd';
import { useLiveQuery } from '@guuru/react-hooks';
import { addResult, removeResult } from '@guuru/graphql-web';
import moment from 'moment';
import { BellOutlined } from '@ant-design/icons';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import GET_PARTNER_NEWS from '../queries/getExpertPartnersNews';
import MARK_NEWS_READ from './mutations/markNewsRead';
import NEWS_BY_EXPERT_CREATED from '../subscriptions/newsCreated';
import NEWS_BY_EXPERT_UPDATED from '../subscriptions/newsUpdated';
import NEWS_BY_EXPERT_DELETED from '../subscriptions/newsDeleted';
import IconButton from '../../../IconButton';
import NewsView from '../../../../views/drawers/NewsView';
import useDrawers from '../../../../hooks/useDrawers';

const News = function () {
  const { open } = useDrawers();
  const { id: userId } = useCurrentUser();

  const [markNewsRead] = useMutation(MARK_NEWS_READ);
  const {
    data: {
      expert: {
        newsReadAt,
        news = {},
      } = {},
    } = {},
    updateQuery,
  } = useLiveQuery(GET_PARTNER_NEWS, NEWS_BY_EXPERT_UPDATED, {
    queryOptions: {
      variables: {
        id: userId,
      },
    },
  });

  useSubscription(NEWS_BY_EXPERT_CREATED, {
    onData: addResult({
      list: (prev) => prev?.expert?.news?.edges,
      element: (result) => result.data.data.newsByExpertCreated,
      updateQuery,
    }),
  });

  useSubscription(NEWS_BY_EXPERT_DELETED, {
    onData: removeResult({
      list: (prev) => prev?.expert?.news?.edges,
      elementId: (result) => result.data.data.newsByExpertDeleted,
      getId: (value) => value.node.id,
      updateQuery,
    }),
  });

  const onClick = async () => {
    await markNewsRead({ variables: { id: userId } });
    open(NewsView);
  };

  const unreadNews = useMemo(() => {
    const lastNewsReadAt = newsReadAt
      || moment().subtract(1, 'months').millisecond();

    return news?.edges?.filter(({ node }) => node.updatedAt > lastNewsReadAt);
  }, [newsReadAt, news]);

  return (
    <Badge
      overflowCount={9}
      count={unreadNews?.length}
      onClick={onClick}
      size="small"
      offset={[-5, 12]}
    >
      <IconButton icon={<BellOutlined />} />
    </Badge>
  );
};

export default News;
