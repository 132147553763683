import { gql } from '@apollo/client';

export default gql`
  query GetExpertAccount($id: ID!) {
    expert(id: $id) {
      id
      account {
        id
        tosAcceptedAt
      }
    }
  }
`;
