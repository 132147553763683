import { useMutation, useSubscription } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Result,
  Button,
  Row,
  Space,
} from 'antd';
import { MailTwoTone } from '@ant-design/icons';
import RESEND_EMAIL from './mutations/resendVerifyEmail';
import EXPERT_EMAIL_VERIFIED from './subscriptions/expertEmailVerified';

const { Paragraph, Text, Title } = Typography;

const EmailUnverified = function ({ email, uid }) {
  useSubscription(EXPERT_EMAIL_VERIFIED, { variables: { id: uid } });
  const [isResent, setIsResent] = useState(false);

  const [resendEmail, { loading }] = useMutation(RESEND_EMAIL);

  const resendVerifyEmail = async () => {
    await resendEmail({
      variables: { id: uid },
    });
    setIsResent(true);
  };

  return (
    <Result
      icon={<MailTwoTone />}
      title={(<Title level={4}>Please verify your email</Title>)}
    >
      <Space direction="vertical" align="center">
        <Paragraph>
          <Row>
            You&apos;re almost there! We sent an email to
          </Row>
          <Row>
            <Text strong>{email}</Text>
          </Row>
        </Paragraph>
        <Paragraph>
          <Row>
            Just click on the link in that email to complete your signup.
          </Row>
          <Row>
            If you don&apos;t see it you may need to check your spam folder.
          </Row>
        </Paragraph>
        <Text>
          {
            isResent
              ? 'We have sent a new verification email to your email'
              : "Still can't find the email?"
          }
        </Text>
        <Button
          type="primary"
          loading={loading}
          disabled={isResent}
          onClick={resendVerifyEmail}
        >
          Resend Email
        </Button>
      </Space>
    </Result>
  );
};

EmailUnverified.propTypes = {
  email: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

export default EmailUnverified;
