import { gql } from '@apollo/client';

export default gql`
  mutation UpdateAccount($accountID: ID!, $accountInput: AccountInput!) {
    updateAccount(id: $accountID, account: $accountInput) {
      id
      birthDate
    }
  }
`;
