import styled from 'styled-components';
import { Form } from 'antd';

const FormItem = styled(Form.Item)`
  .ant-form-item-control-wrapper {
    width: 100%;
  }
`;

export default FormItem;
