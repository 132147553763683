import { gql } from '@apollo/client';

export default gql`
  subscription GetExpertEmailVerified($id: ID!) {
    emailVerified(id: $id) {
      id
      isEmailVerified
    }
  }
`;
