import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Input,
  Button,
  Alert,
  Form,
  Modal,
  Row,
  Col,
} from 'antd';
import { signIn } from '@guuru/auth-web';
import { error, success } from '@guuru/react-message';
import {
  errorCode,
  errorMessage,
  errorType,
} from '@guuru/graphql-web';
import UPDATE_EMAIL from './mutations/updateExpertEmail';
import SEND_CODE from './mutations/sendUpdateEmailVerificationCode';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const UpdateEmail = function () {
  const user = useCurrentUser();
  const [updateEmailForm] = Form.useForm();
  const [codeForm] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [token, setToken] = useState(null);

  const [, forceUpdate] = useState();
  useEffect(() => { forceUpdate({}); }, []);

  const [updateEmail, { loading: isUpdatingEmail }] = useMutation(UPDATE_EMAIL);
  const [sendCode, { loading: isSendingCode }] = useMutation(SEND_CODE);

  const sendEmailVerification = async (values) => {
    const { email } = values;
    try {
      const {
        data: { sendUpdateEmailVerificationCode },
      } = await sendCode({
        variables: {
          id: user.id,
          newEmail: email,
        },
      });
      setModalVisible(true);
      setToken(sendUpdateEmailVerificationCode);
    } catch (e) {
      if (errorCode(e) === errorType.FORBIDDEN) {
        updateEmailForm.setFields([{
          name: 'email',
          errors: [errorMessage(e)],
        }]);
      } else {
        error(e, false);
      }
    }
  };

  const handleUpdateEmail = async (values) => {
    const { inputCode } = values;

    const newEmail = updateEmailForm.getFieldValue('email');
    const password = updateEmailForm.getFieldValue('password');
    try {
      await updateEmail({
        variables: {
          token,
          newEmail,
          inputCode,
        },
      });
      await signIn(newEmail, password);
      success('Email updated successfully');
      setModalVisible(false);
      updateEmailForm.resetFields();
    } catch (err) {
      codeForm.setFields([{
        name: 'inputCode',
        errors: [errorMessage(err)],
      }]);
    }
  };

  const validatePassword = async function (rule, value) {
    try {
      await signIn(user.email, value);
    } catch (err) {
      return Promise.reject(new Error('Current password is incorrect'));
    }
    return Promise.resolve();
  };

  const codeModal = function () {
    if (!modalVisible) {
      return null;
    }
    return (
      <Modal
        title="Enter Your Verification Code"
        destroyOnClose
        open={modalVisible}
        okButtonProps={{ form: 'modal-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={isUpdatingEmail}
        onCancel={() => setModalVisible(false)}
        maskClosable={false}
      >
        <p>Enter the 4-digit code we sent to the email address
          <strong> {updateEmailForm.getFieldValue('email')} </strong>
        </p>
        <Form
          form={codeForm}
          layout="vertical"
          id="modal-form"
          onFinish={handleUpdateEmail}
        >
          <Form.Item
            label=""
            name="inputCode"
            rules={[{
              required: true,
              message: 'Please insert your verification code.',
            },
            {
              len: 4,
              message: 'The verification code cannot be less than 4 digits.',
            },
            {
              pattern: /^[0-9]+$/,
              message: 'The verification code can only have numeric characters.',
            }]}
          >
            <Input
              maxLength="4"
              required
              autoFocus
              style={{ width: '70px' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      {codeModal()}
      <Col span={24}>
        <Alert
          message={(
            <>
              You will receive an email with a verification code to update
              your new email.
              <br />
              Next time you login you should use your updated email address.
            </>
          )}
          type="warning"
          showIcon
        />
      </Col>
      <Col span={24}>
        <Form
          form={updateEmailForm}
          layout="vertical"
          onFinish={sendEmailVerification}
        >
          <Form.Item
            label="Current password"
            name="password"
            validateTrigger="onSubmit"
            rules={[{
              required: true,
              message: 'Please input your current password!',
            },
            { validator: validatePassword }]}
          >
            <Input.Password autoComplete="on" type="password" />
          </Form.Item>
          <Form.Item
            label="New Email"
            name="email"
            rules={[{
              type: 'email',
              message: 'The input is not valid E-mail',
            }, {
              required: true,
              message: 'Please input your E-mail',
            }]}
          >
            <Input autoComplete="on" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !updateEmailForm.isFieldsTouched(true)
                  || (updateEmailForm.getFieldsError()
                    .filter(({ errors }) => errors.length).length
                    && !updateEmailForm.getFieldError('password'))
                }
                loading={isSendingCode}
              >
                Update Email
              </Button>
            )}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default UpdateEmail;
