import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
  Form,
  Typography,
  Input,
  Checkbox,
  Button,
  Col,
  Row,
} from 'antd';
import { signIn } from '@guuru/auth-web';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import ErrorMessages from '../ErrorMessages';
import FormItem from '../FormItem';
import TermsOfServiceContent from '../TermsOfServiceContent';
import CREATE_EXPERT from './mutations/createExpert';

const { Paragraph } = Typography;

const SignUp = function () {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [CreateExpert] = useMutation(CREATE_EXPERT);

  const registerAccount = async (emailInput, passwordInput) => {
    setIsSubmitting(true);
    try {
      const { data } = await CreateExpert({
        variables: {
          input: {
            email: emailInput,
            password: passwordInput,
          },
        },
      });
      if (data.createExpert) {
        await signIn(emailInput, passwordInput);
      }
    } catch (err) {
      setSubmitError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (values) => (
    registerAccount(values.email.trim(), values.password)
  );

  return (
    <>
      {submitError && <ErrorMessages message={submitError} />}
      <Paragraph>Sign up to create your account</Paragraph>
      <Form
        layout="vertical"
        className="login-form"
        onFinish={handleSubmit}
      >
        <FormItem
          name="email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: 'Please type your email address',
            },
            {
              type: 'email',
              message: 'Please type a valid email address',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Your Email"
            autoComplete="username"
            autoFocus
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Your Password"
            autoComplete="current-password"
            prefix={
              <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }
          />
        </FormItem>
        <FormItem
          name="termsAccepted"
          valuePropName="checked"
          rules={[
            {
              validator: async (_, value) => {
                if (!value) {
                  throw Error('Should accept terms and conditions and privacy policy');
                }
              },
            },
          ]}
        >
          <Checkbox>
            <TermsOfServiceContent />
          </Checkbox>
        </FormItem>
        <FormItem>
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Sign up
              </Button>
            </Col>
            <Col span={24} lg={12}>
              <Button block onClick={() => window.history.back()}>
                Back
              </Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </>
  );
};

export default SignUp;
