import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { auth } from '@guuru/auth-web';
import GET_EXPERT_PROFILE from './queries/getExpertProfile';

const useFirebaseCurrentUserId = () => {
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => auth.onAuthStateChanged(setAuthUser), []);
  return { userId: authUser?.uid, loading: authUser === false };
};

export default () => {
  const { userId, loading: isLoadingFirebaseUser } = useFirebaseCurrentUserId();
  const {
    loading: isLoadingUser,
    data: {
      expert: user = {},
    } = {},
  } = useQuery(GET_EXPERT_PROFILE, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  const loading = isLoadingUser || isLoadingFirebaseUser;
  return {
    loading,
    user: {
      ...user,
      locale: user.locale || 'en',
      uid: !loading ? user.id : undefined,
      isAccountVerified: !!user.isEmailVerified,
      isTosAccepted: !!user?.account?.tosAcceptedAt,
    },
  };
};
