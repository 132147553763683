import React from 'react';
import PropTypes from 'prop-types';
import DrawerLayout from '../../containers/DrawerLayout';
import Community
  from '../../components/header/communities/Community';

const { Title, Content } = DrawerLayout;

const CommunityView = function ({ partnerId }) {
  return (
    <DrawerLayout>
      <Title>Community</Title>
      <Content>
        <Community partnerId={partnerId} />
      </Content>
    </DrawerLayout>
  );
};

CommunityView.propTypes = {
  partnerId: PropTypes.string.isRequired,

};

export default CommunityView;
