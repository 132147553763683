import { useMutation } from '@apollo/client';
import React from 'react';
import {
  Button,
  Alert,
  Popconfirm,
  Col,
  Row,
} from 'antd';
import { signOut } from '@guuru/auth-web';
import { success } from '@guuru/react-message';
import DELETE_EXPERT from './mutations/deleteExpert';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const DeleteAccount = function () {
  const { id } = useCurrentUser();
  const [deleteExpert, { loading: isDeleting }] = useMutation(DELETE_EXPERT);

  const handleAccountDelete = async () => {
    await deleteExpert({
      variables: { id },
    });
    success('Account deleted successfully');
    await signOut();
    window.location.reload();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          message="Please be aware that account deletion is immediate and not
          reversible. Your rating and all your personal information,
            including your payment settings, will be lost."
          type="warning"
          showIcon
        />
      </Col>
      <Col span={24}>
        <Popconfirm
          title="Are you sure you want to delete your account?"
          onConfirm={handleAccountDelete}
          okText="Delete"
          okType="danger"
        >
          <Button type="primary" danger loading={isDeleting}>
            { isDeleting ? 'Deleting ' : 'Delete ' }{' '}Account
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};

export default DeleteAccount;
