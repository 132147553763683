import React from 'react';
import ms from 'ms';
import { useLiveQuery } from '@guuru/react-hooks';
import { Typography } from 'antd';
import { GET_EXPERT, BALANCE_SUBSCRIPTION } from '../queries/expert';
import Picture from './Picture';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useDrawers from '../../../hooks/useDrawers';
import ProfileView from '../../../views/drawers/ProfileView';

const Profile = function () {
  const { id: expertId, photoUrl } = useCurrentUser();
  const {
    loading: isLoading,
    data: {
      expert: {
        isAvailable = false,
      } = {},
    } = {},
  } = useLiveQuery(GET_EXPERT, BALANCE_SUBSCRIPTION, {
    queryOptions: {
      variables: { id: expertId },
      // check if expert is still available according to his schedule
      pollInterval: ms('30m'),
    },
    subscriptionOptions: {
      variables: { expertId },
    },
  });

  const { open } = useDrawers();

  return (
    <Typography.Link onClick={() => open(ProfileView)}>
      <Picture
        photoUrl={photoUrl}
        isAvailable={isAvailable}
        isLoading={isLoading}
      />
    </Typography.Link>
  );
};

export default Profile;
