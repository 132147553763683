import React, { useRef } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  App as AntContext, Button, Row, Typography,
} from 'antd';
import { error, success } from '@guuru/react-message';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import ListItem from '../../../../../../ListItem';
import GET_QUIZ_URL from './queries/getQuizUrl';
import JOIN_CATEGORY from './mutations/joinCategory';
import WebViewer from '../../../../../../WebViewer';
import QUIZ_RESOLVED from '../../../subscriptions/quizResolved';

const { Text } = Typography;

const ExpertiseJoinItem = function ({ partnerId, category }) {
  const { id: expertId, email } = useCurrentUser();
  const categoryRequiresVerify = category.requiresVerify;
  const categoryName = category.name.en;
  const { modal } = AntContext.useApp();
  const quizRef = useRef();

  useSubscription(QUIZ_RESOLVED, {
    onData: ({ data: { data } }) => {
      const { quizzResolved } = data;
      if (quizzResolved) {
        setTimeout(() => {
          quizRef.current?.close();
        }, 7500);
      }
    },
  });

  const {
    data: {
      expert: {
        quizUrl,
      } = {},
    } = {},
  } = useQuery(GET_QUIZ_URL, {
    skip: !categoryRequiresVerify,
    variables: {
      id: expertId,
      partnerId,
      category: category.id,
      languageCode: 'en',
    },
  });

  const [joinCategory, { loading }] = useMutation(JOIN_CATEGORY);

  const handleCategoryQuiz = () => {
    const redirectUrl = `${quizUrl}&locale=en&email=${email}`;
    const quiz = modal.confirm({
      mask: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender: () => (
        <WebViewer
          open
          ref={quizRef}
          source={redirectUrl}
          onCancel={() => quiz.destroy()}
        />
      ),
    });
  };

  const handleCategoryJoin = async () => {
    await joinCategory({
      variables: {
        partnerId,
        categoryId: category.id,
      },
    }).catch((err) => {
      error(err.message);
    });
    success(`You joined the ${categoryName} expertise area`);
  };

  return (
    <ListItem>
      <Row justify="space-between">
        <Text>{categoryName}</Text>
        <Button
          type="primary"
          disabled={loading || (!quizUrl && categoryRequiresVerify)}
          loading={loading}
          onClick={() => (
            categoryRequiresVerify ? handleCategoryQuiz() : handleCategoryJoin()
          )}
          style={{ width: 100 }}
        >
          Join
        </Button>
      </Row>
    </ListItem>
  );
};

ExpertiseJoinItem.propTypes = {
  partnerId: PropTypes.string.isRequired,
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string,
    }),
    requiresVerify: PropTypes.bool,
  }).isRequired,
};

export default ExpertiseJoinItem;
