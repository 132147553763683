import { gql } from '@apollo/client';

export default gql`
  subscription InboxUpdateChat($id: ID!) {
    inboxUpdateChat(id: $id) {
      id
      lastMessage {
        id
        text
        createdAt
        author {
          __typename
          ... on BotAuthor {
            id
            name
            photoUrl
          }
          ... on UserAuthor {
            id
            name
            phone
            photoUrl
          }
          ... on ExpertAuthor {
            id
            name
            photoUrl
          }
        }
      }
      expert {
        id
      }
      status
    }
  }
`;
