import { gql } from '@apollo/client';

export default gql`
  query GetPartnerLogoUrl($id: ID!) {
    partner(id: $id) {
      id
      settings {
        id
        appearance {
          chatWidgetPhotoUrl
        }
      }
    }
  }
`;
