import React from 'react';
import { Space, Typography } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Communities = function () {
  return (
    <Space direction="vertical" align="center">
      <Text>You have finished your profile tutorial!</Text>
      <Text>
        Now you can select Communities to join as an Expert.
        To pick them click on the left section
        icon <TeamOutlined style={{
        background: 'deepskyblue',
        borderRadius: '50%',
        color: 'white',
        fontSize: 18,
        padding: 3,
      }}
        />
      </Text>
      <Text>
        There you will be able to join new communities that are available!
      </Text>
    </Space>
  );
};

export default Communities;
