import {
  Col,
  Row,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const { Text } = Typography;

const ConditionItem = function ({ title, description, icon: Icon }) {
  return (
    <Col flex="100%">
      <Row gutter={[8, 8]} wrap={false}>
        <Col flex="none">
          <Icon style={{ fontSize: 30 }} />
        </Col>
        <Col flex="auto">
          <Space direction="vertical" size={2}>
            <Text>{title}</Text>
            <Text type="secondary">{description}</Text>
          </Space>
        </Col>
      </Row>
    </Col>
  );
};

ConditionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};

export default ConditionItem;
