import React from 'react';
import { Card, Row, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import JoinButton from './JoinButton';
import ConditionsCard from './ConditionsCard';

const JoiningCards = function ({ partner }) {
  const {
    id: partnerId,
    name,
    pricing = [],
    settings: {
      community: {
        requiresVerify,
        recruiting: isOpen = false,
      } = {},
    } = {},
  } = partner;

  return (
    <>
      <ConditionsCard
        currency={pricing[0]?.currency}
        requiresVerify={requiresVerify}
      />

      <Card>
        <Row justify="center">
          {isOpen ? (
            <JoinButton partnerId={partnerId} />
          ) : (
            <Typography.Text>
              {`Looks like ${name.en} is currently not looking for new community members`}
            </Typography.Text>
          )}
        </Row>
      </Card>
    </>
  );
};

JoiningCards.propTypes = {
  partner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired,
    }).isRequired,
    pricing: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
      }),
    ),
    settings: PropTypes.shape({
      community: PropTypes.shape({
        recruiting: PropTypes.bool.isRequired,
        requiresVerify: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default JoiningCards;
