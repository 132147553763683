import { gql } from '@apollo/client';

export default gql`
  query GetPartnerCategories($id: ID!, $active: Boolean!) {
    partner(id: $id) {
      id
      categories(active: $active) {
        id
        name
        requiresVerify
      }
    }
  }
`;
