import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Avatar, Space, Typography, Col, Card, Rate,
} from 'antd';
import LanguageFlag from '@guuru/react-language-flag';
import { scaleRating } from '@guuru/rating-common';
import GET_EXPERT_DETAILS from './queries/GetExpertDetails';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

const { Title, Text } = Typography;

const ExpertProfile = function ({ partner }) {
  const { id: expertId } = useCurrentUser();
  const {
    data: {
      expert,
    } = {},
  } = useSuspenseQuery(GET_EXPERT_DETAILS, {
    variables: {
      id: expertId,
    },
  });

  const {
    name,
    photoUrl,
    rating,
    languages = [],
  } = expert ?? {};

  const { joinedAt, bio } = partner;

  const ratingScalated = scaleRating(rating) || 0;

  const profileDate = new Date(joinedAt).toLocaleString('en', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <Card>
      <Col align="center">
        <Space direction="vertical" size="middle">
          <Avatar src={photoUrl} style={{ width: 160, height: 160 }} />
          <Title level={2} style={{ color: 'black' }}>{name}</Title>
          <Text>Member since {profileDate}</Text>
          <Space>
            <Rate
              value={ratingScalated}
              allowHalf
              disabled
              style={{ fontSize: 16 }}
            />
            <Col style={{ marginTop: 1 }}>
              <Text strong>{ratingScalated.toFixed(1)}</Text>
            </Col>
          </Space>
          <Space>
            {languages.map((language) => (
              <LanguageFlag
                key={`expert_language_${language}`}
                languageCode={language}
              />
            ))}
          </Space>
          <Text type="secondary">{bio}</Text>
        </Space>
      </Col>
    </Card>
  );
};

ExpertProfile.propTypes = {
  partner: PropTypes.shape({
    joinedAt: PropTypes.number,
    bio: PropTypes.string,
  }).isRequired,
};

export default ExpertProfile;
