import React from 'react';
import PropTypes from 'prop-types';
import { Col, List, Row } from 'antd';
import PaddingX from './PaddingX';

const ListItem = function ({
  onClick = () => {},
  avatar = undefined,
  children = undefined,
}) {
  return (
    <Col flex="auto">
      <List.Item onClick={onClick}>
        <Col span={24}>
          <Row align="middle" wrap={false}>
            {avatar && (
              <Col flex="none">
                <PaddingX right size="middle">
                  {avatar}
                </PaddingX>
              </Col>
            )}
            <Col flex="auto" style={{ borderTop: '1px solid #f0f0f0', padding: '8px 0' }}>
              {children}
            </Col>
          </Row>
        </Col>
      </List.Item>
    </Col>
  );
};

ListItem.propTypes = {
  onClick: PropTypes.func,
  avatar: PropTypes.node,
  children: PropTypes.node,
};

export default ListItem;
