import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FullscreenModal = styled(Modal)`
  margin: 0 !important;
  top: 0;
  height: 100%;
  padding: 32px;
  max-width: 100%;
  width: 100% !important;

  .ant-modal, .ant-modal-content {
    height: calc(100vh - 64px);
  }
  
  .ant-modal-body {
    height: 100%
  }

  .ant-modal-close {
    color: rgb(0 0 0 / 45%);
  }

  iframe {
    border: none;
  }
`;

const WebViewer = forwardRef(({
  open,
  onCancel,
  source,
  children,
  ...rest
}, ref) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: handleCancel,
  }));

  return (
    <FullscreenModal
      {...rest}
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <iframe
        title="Guuru Quiz"
        width="100%"
        height="100%"
        src={source}
      />
    </FullscreenModal>
  );
});

WebViewer.propTypes = {
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  source: PropTypes.string,
  children: PropTypes.node,
};

WebViewer.defaultProps = {
  onCancel: () => {},
  open: false,
  children: null,
  source: null,
};

export default WebViewer;
