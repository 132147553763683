import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { RequireAuth } from '@guuru/react-routing';
import App from './App';
import useNotificationsCheck from './hooks/useNotifications';
import SidebarContainer from './SidebarContainer';
import useAppCheck from './hooks/useAppCheck';
import useCurrentUser from '../../hooks/useCurrentUser';

const ExpertLayout = function () {
  const user = useCurrentUser();

  useNotificationsCheck();
  useAppCheck();

  return (
    <RequireAuth session={user}>
      <App>
        <Layout>
          <SidebarContainer />
          <Layout>
            <Layout.Content>
              <Outlet />
            </Layout.Content>
          </Layout>
        </Layout>
      </App>
    </RequireAuth>
  );
};

export default ExpertLayout;
