import React from 'react';
import { Grid, Layout, theme } from 'antd';
import ThemeProvider from '@guuru/theme-web';
import DrawerStack from '../../components/DrawerStack';
import ChatsList from '../../components/inbox/ActiveConversations';
import InboxHeader from '../../components/header';
import useOnboarding from '../../hooks/useOnboarding';

const { useToken } = theme;

const { useBreakpoint } = Grid;
const siders = {
  xxl: 470, xl: '30%', lg: 310, xs: '85%',
};

const SidebarContainer = function () {
  useOnboarding();
  const screens = useBreakpoint();
  const breakpoint = Object.keys(siders).find((size) => screens[size]);

  const width = siders[breakpoint];
  const { token } = useToken();

  return (
    <ThemeProvider
      theme={{ color: token.colorPrimary }}
      configProvider={{
        token: {
          colorIcon: token.colorWhite,
          colorLink: token.colorPrimary,
        },
        components: {
          Drawer: {
            boxShadowDrawerLeft: '1px 0px 0px 0 #f0f0f0',
            paddingLG: 0,
            padding: 0,
          },
          Typography: {
            colorTextHeading: token.colorWhite,
          },
          Card: {
            colorTextHeading: token.colorPrimary,
            borderRadiusLG: 0,
            lineWidth: 0,
            fontSizeLG: 14,
          },
          Rate: {
            marginXS: 4,
            controlHeightLG: 16.5,
          },
        },
      }}
    >
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth={0}
        width={width || '30%'}
      >
        <DrawerStack>
          <InboxHeader />
          <ChatsList />
        </DrawerStack>
      </Layout.Sider>
    </ThemeProvider>
  );
};

export default SidebarContainer;
