import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Popconfirm,
  Typography,
  Col,
  Tooltip,
} from 'antd';
import { error, success } from '@guuru/react-message';
import log from '@guuru/logger-web/src/logger';
import { useMutation } from '@apollo/client';
import { LocaleHelper } from '@guuru/helpers-common';
import { DeleteOutlined } from '@ant-design/icons';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import REMOVE_EXPERT_FROM_ROLE from './mutations/removeExpertFromRole';

const { localize } = LocaleHelper;

const { Paragraph } = Typography;

const confirmText = () => (
  <Col style={{ width: 350 }}>
    <Paragraph>Are you sure you want to leave this community? </Paragraph>
    <Paragraph>
      If you want to join this community again,
      you will need to follow the approval process again.
    </Paragraph>
  </Col>
);

const LeavePartnerButton = function ({ partnerId, name }) {
  const { id: expertId } = useCurrentUser();
  const [
    removeExpertFromRole,
    { loading: isLeaving },
  ] = useMutation(REMOVE_EXPERT_FROM_ROLE);

  const handleLeavePartner = async () => {
    try {
      await removeExpertFromRole({
        variables: { expertId, partnerId, role: 'guuru' },
      });
      success(`You left the ${localize(name)} community`);
    } catch (e) {
      log.error(e);
      error(e.message);
    }
  };

  return (
    <Popconfirm
      title={confirmText}
      onConfirm={handleLeavePartner}
    >
      <Tooltip
        title="Leave Community"
      >
        <Button
          block
          size="middle"
          loading={isLeaving}
          danger
          icon={<DeleteOutlined />}
        >
          Leave
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

LeavePartnerButton.propTypes = {
  partnerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

};

export default LeavePartnerButton;
