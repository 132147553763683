import React from 'react';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import StarOutlined from '@ant-design/icons/StarOutlined';
import PropTypes from 'prop-types';
import { LoadMore } from '@guuru/react-pagination';
import { Card, Empty as AntEmpty } from 'antd';
import useResolvedConversations from '../../hooks/useResolvedConversations';
import ChatsByState from '../inbox/ChatsByState';
import Loading from '../inbox/Loading';
import useCurrentUser from '../../hooks/useCurrentUser';
import Empty from '../Empty';

const ConversationHistory = function ({ positiveFeedback = false }) {
  const { id: expertId } = useCurrentUser();

  const {
    pageInfo,
    archivedChats,
    loading,
    fetchMore,
  } = useResolvedConversations(expertId, {
    skipSubscription: true,
    filters: positiveFeedback
      ? [{ key: 'hasFeedback' }, { key: 'rating', value: [1] }]
      : undefined,
  });

  const getDatasource = (status) => archivedChats
    ?.map((chat) => ({ ...chat.node }))
    .filter((chat) => chat.status === status);

  if (loading) {
    return <Loading />;
  }

  if (!archivedChats.length) {
    const description = positiveFeedback
      ? 'Seems you have not received any positive feedback this month'
      : 'Seems you have not accepted any request yet';
    return (
      <Card>
        <Empty
          description={description}
          image={AntEmpty.PRESENTED_IMAGE_SIMPLE}
        />
      </Card>
    );
  }

  return (
    <>
      <ChatsByState
        title="Closed"
        icon={<CheckOutlined />}
        chats={getDatasource('closed')}
      />
      <ChatsByState
        title="Rated"
        icon={<StarOutlined />}
        chats={getDatasource('rated')}
        loadMore={(
          <LoadMore
            data={{ pageInfo }}
            fetchMore={fetchMore}
            loading={loading}
          />
        )}
      />
    </>
  );
};

ConversationHistory.propTypes = {
  positiveFeedback: PropTypes.bool,
};

export default ConversationHistory;
