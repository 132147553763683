import React from 'react';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Spin } from 'antd';
import Inbox from './Inbox';
import Spinner from './Spinner';

const Loading = function () {
  return (
    <Inbox span={24}>
      <Spinner justify="center" align="middle">
        <Spin indicator={(
          <LoadingOutlined style={{ fontSize: 36 }} spin />
        )}
        />
      </Spinner>
    </Inbox>
  );
};

export default Loading;
