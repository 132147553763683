import React from 'react';
import { Layout } from 'antd';
import { Footer } from '@guuru/react-footer';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const BasicLayout = function () {
  return (
    <Layout>
      <Content>
        <Outlet />
      </Content>
      <Footer style={{ background: 'white' }} />
    </Layout>
  );
};

export default BasicLayout;
