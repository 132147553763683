import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { client } from '@guuru/graphql-web';
import UPDATE_PUSH_TOKEN_WEB from './mutations/updatePushTokenWeb';

const buildPushToken = (token, enabled, error = false) => ({
  pushToken: token,
  enabled,
  error,
});

const getCurrentToken = () => (
  getToken(getMessaging(), {
    vapidKey: process.env.FIREBASE_MESSAGING_VAPID_PUBLIC_KEY,
  })
);

const requestPermission = () => (
  getCurrentToken()
    .then((currentToken) => buildPushToken(currentToken, true))
    .catch(() => buildPushToken(null, false))
);

const requestPermissionIfNeeded = async () => {
  const isBrowserSupported = await isSupported();
  if (!isBrowserSupported) {
    return buildPushToken(null, false, true);
  }
  return getCurrentToken()
    .then((currentToken) => {
      if (currentToken) {
        return buildPushToken(currentToken, true);
      }
      return requestPermission();
    })
    .catch(() => buildPushToken(null, false));
};

const updateUserTokenIfNeeded = async (uid, token) => {
  if (uid && token) {
    await client.mutate({
      mutation: UPDATE_PUSH_TOKEN_WEB,
      variables: {
        id: uid,
        pushTokenWeb: token,
      },
    });
  }
  return false;
};

const isBrowserSupported = () => (isSupported());

export {
  updateUserTokenIfNeeded,
  requestPermissionIfNeeded,
  requestPermission,
  isBrowserSupported,
};
