import { Card, Row } from 'antd';
import React from 'react';
import { StarOutlined, DollarOutlined, SafetyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Condition from './ConditionItem';

const ConditionsCard = function ({ currency = null, requiresVerify = false }) {
  const isPoints = currency === 'XXX';

  return (
    <Card>
      <Row gutter={[16, 16]}>
        {currency && (
          <Condition
            icon={isPoints ? StarOutlined : DollarOutlined}
            title={isPoints ? 'Earn Points' : 'Earn Money'}
            description={isPoints ? 'Collect valuable points with good answers' : 'Get paid to each answered chat'}
          />
        )}
        {requiresVerify && (
          <Condition
            icon={SafetyOutlined}
            title="Verified members only"
            description={'Only users that match this group\'s criteria can join'}
          />
        )}
      </Row>
    </Card>
  );
};

ConditionsCard.propTypes = {
  currency: PropTypes.string,
  requiresVerify: PropTypes.bool,
};

export default ConditionsCard;
