import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const ChatTooltip = function ({
  title,
  placement = 'top',
  size = 'small',
  distance = '0',
  delay = '300',
  className = '',
  children = null,
}) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      size={size}
      distance={distance}
      delay={delay}
      className={className}
      arrow={{ pointAtCenter: true }}
    >
      {children}
    </Tooltip>
  );
};

ChatTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  size: PropTypes.string,
  distance: PropTypes.string,
  delay: PropTypes.string,
  children: PropTypes.shape(),
  className: PropTypes.string,
};

export default ChatTooltip;
