import React from 'react';
import { signOut } from '@guuru/auth-web';
import { Dropdown, Row } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import useDrawers from '../../hooks/useDrawers';
import HistoryView from '../../views/drawers/HistoryView';
import SettingsView from '../../views/drawers/SettingsView';
import PayoutsView from '../../views/drawers/PayoutsView';
import IconButton from '../IconButton';

const MoreMenu = function () {
  const { open } = useDrawers();

  const handleSignOut = async () => {
    await signOut();
    window.location.reload();
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'history',
            label: (
              <Row onClick={() => open(HistoryView)}>
                Conversation History
              </Row>
            ),
          },
          {
            key: 'payouts',
            label: (
              <Row onClick={() => open(PayoutsView)}>
                Payouts
              </Row>
            ),
          },
          {
            key: 'settings',
            label: (
              <Row onClick={() => open(SettingsView)}>
                Settings
              </Row>
            ),
          },
          { type: 'divider' },
          {
            key: 'logout',
            label: (
              <Row onClick={handleSignOut}>
                Sign out
              </Row>
            ),
          },
        ],
      }}
      trigger={['click']}
      placement="bottomRight"
    >
      <span>
        <IconButton
          icon={<MoreOutlined />}
          onClick={(e) => e.preventDefault()}
        />
      </span>
    </Dropdown>
  );
};

export default MoreMenu;
