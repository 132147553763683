import { gql } from '@apollo/client';

const inboxChat = gql`
  fragment InboxChat on Chat {
    id
    question
    createdAt
    acceptedAt
    status
    closedAt
    rating
    languageCode
    expertIsTyping
    userIsTyping
    lastExpertMessageAt
    lastUserMessageAt
    lastSeenByExpertAt
    lastMessage {
      id
      text
      createdAt
      author {
        __typename
        ... on BotAuthor {
          id
        }
        ... on UserAuthor {
          id
        }
        ... on ExpertAuthor {
          id
        }
      }
    }
    user {
      id
      name
    }
    expert {
      id
    }
    partner {
      id
      name
      settings {
        id
        appearance {
          chatWidgetPhotoUrl
        }
      }
    }
    hotTransfers {
      id
     }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export default inboxChat;
