import { gql } from '@apollo/client';

export default gql`
query GetExpertDetails($id: ID!) {
  expert(id: $id) {
    id
    name
    email
    photoUrl
    rating
    languages
  }
}
`;
