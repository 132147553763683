import { Col, Space } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const PaddingX = function ({
  left = false,
  right = false,
  size = undefined,
  children,
}) {
  return (
    <Space size={size}>
      {left && <Col />}
      {children}
      {right && <Col />}
    </Space>
  );
};

PaddingX.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.node.isRequired,
};

export default PaddingX;
