import React from 'react';
import PropTypes from 'prop-types';
import DrawerLayout from '../../containers/DrawerLayout';
import ExpertProfile from '../../components/header/communities/Community/CommunityButtons/ExpertProfile';

const { Title, Content } = DrawerLayout;

const ExpertProfileView = function ({ partner }) {
  return (
    <DrawerLayout>
      <Title>You</Title>
      <Content>
        <ExpertProfile partner={partner} />
      </Content>
    </DrawerLayout>
  );
};

ExpertProfileView.propTypes = {
  partner: PropTypes.shape({
    joinedAt: PropTypes.number,
    bio: PropTypes.string,
  }).isRequired,
};

export default ExpertProfileView;
