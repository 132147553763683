import React, { useMemo } from 'react';
import {
  Badge,
  Button,
  theme,
  Typography,
} from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import useDrawers from '../../hooks/useDrawers';
import FeedbackView from '../../views/drawers/FeedbackView';
import useResolvedConversations from '../../hooks/useResolvedConversations';
import useCurrentUser from '../../hooks/useCurrentUser';

const { useToken } = theme;

const FeedbackButton = function () {
  const { token } = useToken();
  const { id: expertId } = useCurrentUser();
  const { open } = useDrawers();

  const {
    archivedChats = [],
    count: positiveFeedbacksCount = '—',
  } = useResolvedConversations(expertId, {
    filters: [{ key: 'hasFeedback' }, { key: 'rating', value: [1] }],
  });

  const unreadFeedbacks = useMemo(() => (
    archivedChats
      .slice(0, 10)
      .reduce(((acc, { node: { ratedAt, lastSeenByExpertAt } = {} }) => (
        ratedAt > lastSeenByExpertAt ? acc + 1 : acc
      )), 0)
  ), [archivedChats]);

  return (
    <Badge
      overflowCount={9}
      count={unreadFeedbacks}
      size="small"
      offset={[-5, 12]}
    >
      <Button
        type="text"
        size="large"
        icon={<LikeOutlined />}
        style={{ color: token.colorIcon }}
        onClick={() => open(FeedbackView)}
      >
        <Typography.Text style={{ color: token.colorIcon }}>
          {positiveFeedbacksCount}
        </Typography.Text>
      </Button>
    </Badge>
  );
};

export default FeedbackButton;
