import { gql } from '@apollo/client';

export default gql`
  fragment ExpertPartners on Expert {
    submissions(
      first: 10
      filters: [
        {key: status, value: ["pending"]},
        {key: partnerStatus, value: "active"},
      ]
    ) {
      edges {
        node {
          createdAt
          partner {
            id
            name
            description
            settings {
              id
              appearance {
                chatWidgetPhotoUrl
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    partners {
      edges {
        node {
          id
          name
          description
          settings {
            id
            appearance {
              chatWidgetPhotoUrl
            }
          }
        }
        isEnabled
        joinedAt
        categories {
          node {
            id
            name
            requiresVerify
          }
          isEnabled
        }
        bucket
        roles
        bio
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
