import { gql } from '@apollo/client';
import INBOX_CHAT from '../fragments/inboxChat';

export default gql`
  ${INBOX_CHAT}
  query GetExpertInbox($id: ID!) {
    expert(id: $id) {
      id
      inbox {
        ...InboxChat
      }
    }
  }
`;
