import { gql } from '@apollo/client';

export default gql`
  query GetExpert($id: ID!) {
    expert(id: $id) {
      id
      languages
    }
    languages {
      name
      nameTranslate
      color
      code
      countryCode
    }
  }
`;
