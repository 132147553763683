import { gql } from '@apollo/client';

export default gql`
  query GetActiveVisiblePartners($filters: [PartnerFilterType], $first: Int, $after: String) {
    partners(filters: $filters, first: $first, after: $after) {
      edges {
        node {
          id
          name
          description
          settings {
            id
            appearance {
              chatWidgetPhotoUrl
            }
            community {
              id
              recruiting
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
