import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import {
  Col, Radio, Row, Select, Slider,
} from 'antd';
import { Field, Form, useForm } from '@guuru/react-forms';
import moment
  from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import capitalize from 'lodash/capitalize';
import GET_EXPERT_AVAILABILITY from './queries/getExpertAvailability';
import UPDATE_EXPERT_AVAILABILITY from './mutations/updateAvailability';
import useCurrentUser from '../../../hooks/useCurrentUser';

const WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const minutesToHousFormatter = (minutes = 0) => (
  minutes === 1440
    ? '24:00'
    : moment().hours(0).minutes(minutes).format('HH:mm')
);

const hoursToMinutes = (time) => {
  const timeSplit = time.split(':');
  return (parseInt(timeSplit[0], 10) * 60) + parseInt(timeSplit[1], 10);
};

const minutesToHours = (m) => {
  const hours = (m / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const formatHours = (`0${rhours}`).slice(-2);
  const formatMinutes = (`0${rminutes}`).slice(-2);
  return `${formatHours}${formatMinutes}`;
};

const CustomSchedule = function ({ showTime }) {
  return WEEK_DAYS.map((weekDay, index) => (
    <Row key={weekDay} gutter={[16, 16]} style={{ width: '100%' }}>
      <Col span={8} key={`label-${weekDay}`}>
        <Row key={`weekday-${weekDay}`}>
          {`${capitalize(weekDay)} `}
        </Row>
        <Row key={`time-${weekDay}`}>
          {`${showTime(index)} `}
        </Row>
      </Col>

      <Col span={16} key={weekDay}>
        <Field label="" key={weekDay} name={weekDay}>
          <Slider
            range
            min={0}
            max={1440}
            marks={{
              0: '00:00',
              720: '12:00',
              1440: '24:00',
            }}
            tooltip={{ formatter: minutesToHousFormatter }}
            step={30}
          />
        </Field>
      </Col>
    </Row>
  ));
};

const TimezoneSelect = function () {
  return (
    <Field
      label="Timezone"
      help="Select a Timezone"
      key="timezone"
      name="expertAvailabilityTimezone"
      rules={[
        {
          required: true,
          message: 'Please select a timezone',
        },
      ]}
    >
      <Select showSearch>
        {moment.tz.names().map((zone) => (
          <Select.Option
            value={zone}
            key={zone}
          >
            {zone}
          </Select.Option>
        ))}
      </Select>
    </Field>
  );
};

const AvailabilitySettings = function () {
  const [form] = useForm();
  const { id: expertId } = useCurrentUser();

  const [
    updateAvailability, {
      loading: updating,
    },
  ] = useMutation(UPDATE_EXPERT_AVAILABILITY);

  const {
    data: {
      expert: {
        availability: {
          status,
          timezone,
          schedule,
        } = {},
      } = {},
    } = {},
  } = useSuspenseQuery(GET_EXPERT_AVAILABILITY, {
    variables: {
      id: expertId,
    },
  });

  const getInterval = (weekday) => (
    schedule.find((s) => s.weekday === weekday)
    || { start: '00:00:00', end: '24:00:00' }
  );

  const startOfSchedule = (weekday) => (
    hoursToMinutes(getInterval(weekday).start)
  );

  const endOfSchedule = (weekday) => (
    hoursToMinutes(getInterval(weekday).end)
  );

  const showTime = (weekday) => {
    const day = WEEK_DAYS[weekday];
    return form.getFieldValue(day)
      ? `${minutesToHousFormatter(form.getFieldValue(day)[0])} - ${minutesToHousFormatter(form.getFieldValue(day)[1])}`
      : `${getInterval(weekday).start.slice(0, -3)} - ${getInterval(weekday).end.slice(0, -3)}`;
  };

  const onFinish = async (values) => {
    const updateSchedule = values.statusAvailability === 'custom'
      ? WEEK_DAYS.map((weekDay, index) => (
        {
          weekday: index,
          start: (minutesToHours(values[weekDay][0])),
          end: (minutesToHours(values[weekDay][1])),
        }
      ))
      : undefined;
    await updateAvailability({
      variables: {
        id: expertId,
        availability: {
          status: values.statusAvailability,
          timezone: values.statusAvailability === 'custom'
            ? (values.expertAvailabilityTimezone || moment.tz.guess())
            : undefined,
          schedule: values.statusAvailability === 'custom'
            ? updateSchedule
            : undefined,
        },
      },
    });
  };

  const options = {
    never: 'You wont receive any conversations',
    always: 'You will receive conversations at any time',
    custom: 'Select in which periods you are available',
  };

  const initialValues = () => {
    const values = {
      statusAvailability: status || 'never',
      expertAvailabilityTimezone: timezone || moment.tz.guess(),
    };

    WEEK_DAYS.forEach((weekDay, index) => {
      values[weekDay] = [startOfSchedule(index), endOfSchedule(index)];
    });
    return values;
  };

  return (
    <Form
      form={form}
      onSave={onFinish}
      isSaving={updating}
      savingMessage="Updating availability..."
      savedMessage="Availability updated"
      initialValues={initialValues()}
      saveLabel="Update Availability"
    >
      <Field
        noStyle
        shouldUpdate={(prevValues, curValues) => (
          prevValues.statusAvailability !== curValues.statusAvailability
        )}
      >
        {({ getFieldValue }) => {
          const currValue = getFieldValue('statusAvailability')
              || status
              || 'never';

          const base = (
            <Field
              key="statusAvailability"
              name="statusAvailability"
              label="Your availability"
              help={options[currValue]}
              rules={[
                {
                  required: true, message: 'Please select your availability!',
                },
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="always">Always</Radio.Button>
                <Radio.Button value="never">Never</Radio.Button>
                <Radio.Button value="custom">Custom</Radio.Button>
              </Radio.Group>
            </Field>
          );

          if (currValue === 'custom') {
            return [
              base,
              <TimezoneSelect key="timezone" />,
              <CustomSchedule key="schedule" showTime={showTime} />,
            ];
          }
          return base;
        }}

      </Field>
    </Form>
  );
};

export default AvailabilitySettings;
