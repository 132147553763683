import React, { useEffect } from 'react';
import { MobileOutlined } from '@ant-design/icons';
import { App as AntContext, Typography } from 'antd';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const { Link } = Typography;

const useNotifications = function () {
  const { notification } = AntContext.useApp();
  const user = useCurrentUser();

  const showNotification = () => {
    notification.info({
      message: 'Receive notifications anywhere and anytime',
      description: (
        <>
          Install <Link href="https://guuru.app.link/install" target="_blank">our mobile phone app</Link> to receive real-time notifications wherever you are.
        </>
      ),
      key: 'install-app',
      duration: 0,
      icon: <MobileOutlined style={{ color: '#108ee9' }} />,
      placement: 'top',
      style: { width: 600 },
    });
  };

  useEffect(() => {
    if (!!user?.roles?.some((r) => r.role === 'guuru') && !user.pushToken) {
      showNotification();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNotifications;
