import React from 'react';
import { Card, List } from 'antd';
import { LoadMore } from '@guuru/react-pagination';
import { useSuspenseTranslations } from '@guuru/react-hooks';
import GuuruShrugs from '../../../../assets/images/GuuruShrugs.svg';
import Empty from '../../../Empty';
import CommunityListItem from '../CommunityListItem';
import usePartnersListOpen from '../../../../hooks/useOpenPartnersList';

const OpenCommunitiesList = function () {
  const {
    partners,
    loading,
    pageInfo,
    fetchMore,
  } = usePartnersListOpen();

  const {
    data: {
      joinCommunitiesEmptyHeader = '',
      joinCommunitiesEmptyDescription = '',
    } = {},
  } = useSuspenseTranslations(
    ['joinCommunitiesEmptyHeader', 'joinCommunitiesEmptyDescription'],
  );

  return !partners.length ? (
    <Card>
      <Empty
        image={<GuuruShrugs />}
        title={joinCommunitiesEmptyHeader}
        description={joinCommunitiesEmptyDescription}
      />
    </Card>
  ) : (
    <List
      itemLayout="vertical"
      dataSource={partners}
      loading={loading}
      loadMore={(
        <LoadMore
          data={{ pageInfo }}
          fetchMore={fetchMore}
          loading={loading}
        />
      )}
      renderItem={(partner) => (
        <CommunityListItem partner={partner} />
      )}
    />
  );
};

export default OpenCommunitiesList;
