import React, { useMemo } from 'react';
import { Table } from 'antd';
import Balance from '@guuru/react-balance';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PayoutTable } from './style';

const Payout = function ({ statement = {} }) {
  const getProductName = (product, period) => {
    if (!product) {
      return '';
    }
    if (moment(period).isBefore(moment(), 'month') && product === 'payments') {
      return 'Payments delayed to next month (delayed from this or/and previous months)';
    }
    if (product === 'payments') {
      return 'Payments (Delayed payment from previous months)';
    }
    return startCase(product);
  };

  const statements = useMemo(() => orderBy(statement?.node?.statements, 'product')
    .map((row, index) => ({ ...row, index })), [statement?.node?.statements]);

  return (
    <PayoutTable
      rowKey="index"
      dataSource={statements}
      pagination={false}
      size="small"
    >
      <Table.Column
        title="Product"
        width="75%"
        dataIndex="product"
        key="product"
        render={(text, node) => getProductName(
          node.product,
          statement?.node?.period,
        )}
      />
      <Table.Column
        title="Balance"
        width="25%"
        align="right"
        dataIndex="balances"
        key="balances"
        render={(text, node) => (
          <Balance balances={node?.balances} />
        )}
      />
    </PayoutTable>
  );
};

Payout.propTypes = {
  statement: PropTypes.shape({
    node: PropTypes.shape({
      period: PropTypes.number,
      statements: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default Payout;
