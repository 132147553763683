import { useMemo } from 'react';
import { LocaleHelper } from '@guuru/helpers-common';
import { i18n, useLoadTranslations } from '@guuru/translation-web';

const useInitLocale = (locale) => {
  const systemLocale = useMemo(() => {
    const sysLocale = LocaleHelper.getValidSystemLocale({
      locale: locale || LocaleHelper.getSystemLocale(),
    });
    LocaleHelper.storeSystemLocale(sysLocale);

    i18n.changeLanguage(sysLocale);
    return sysLocale;
  }, [locale]);

  useLoadTranslations(systemLocale, ['bot', 'chat']);
};

export default useInitLocale;
