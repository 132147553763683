import React from 'react';
import DrawerLayout from '../../containers/DrawerLayout';
import ProfileSettings from '../../components/settings/ProfileSettings';

const { Title, Content } = DrawerLayout;

const ProfileView = function () {
  return (
    <DrawerLayout>
      <Title>Profile</Title>
      <Content>
        <ProfileSettings />
      </Content>
    </DrawerLayout>
  );
};

export default ProfileView;
