import styled from 'styled-components';

const App = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  display: flex;
  overflow: hidden;
  animation: zoom-out 0.3s cubic-bezier(0.1, 0.82, 0.25, 1);

  @keyframes zoom-out {
    0% {
      opacity: 0;
      transform: scale(1.4);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .ant-layout {
    .ant-layout-sider-zero-width-trigger {
      background-color: ${({ theme }) => theme.primaryColor};
      z-index: 9999;
      inset-inline-end: -39px;
    }

    .ant-layout-content {
      display: flex;
    }

    .ant-layout-sider {
      z-index: 100;
      height: 100%;
      background-color: ${({ theme }) => theme.backgroundColor};

      @media only screen and (max-width: 991px) {
        position: fixed;
      }

      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        border-right: 1px solid ${({ theme }) => theme.borderColor};
      }
    }
  }

  .ant-collapse-borderless > .ant-collapse-item:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

export default App;
