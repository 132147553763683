import React, { useEffect } from 'react';
import { App as AntContext } from 'antd';
import Onboarding from '../components/Onboarding';
import useCurrentUser from './useCurrentUser';

const useOnboarding = () => {
  const user = useCurrentUser();
  const { modal } = AntContext.useApp();

  useEffect(() => {
    if (!user.name) {
      const modalRef = modal.confirm({
        mask: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        modalRender: () => <Onboarding onFinish={modalRef.destroy} />,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnboarding;
