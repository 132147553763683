import React from 'react';
import DrawerLayout from '../../containers/DrawerLayout';
import OpenCommunitiesList from '../../components/header/communities/OpenCommunitiesList';

const { Title, Content } = DrawerLayout;

const OpenCommunitiesView = function () {
  return (
    <DrawerLayout>
      <Title>Open Communities</Title>
      <Content>
        <OpenCommunitiesList />
      </Content>
    </DrawerLayout>
  );
};

export default OpenCommunitiesView;
