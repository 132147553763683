import {
  Avatar, Card, Col, Typography, Row, Space, Tooltip,
} from 'antd';
import Linkify from '@guuru/react-linkify';
import React from 'react';
import { LocaleHelper } from '@guuru/helpers-common';
import { MarkdownRender } from '@guuru/react-markdown-render';
import PropTypes from 'prop-types';
import ListItem from '../../../ListItem';
import DateTime from '../../../DateTime';

const { Text } = Typography;
const { localize } = LocaleHelper;

const NewsItem = function ({ item, locale = 'en' }) {
  const {
    partner: {
      name: partnerName,
      settings: {
        appearance: {
          chatWidgetPhotoUrl,
        } = {},
      } = {},
    } = {},
    title,
    updatedAt,
    message,
  } = item;
  return (
    <ListItem>
      <Card>
        <Space direction="vertical" size="small" align="justify">
          <Space size="small" align="top">
            <Col flex="none">
              <Tooltip title={localize(partnerName, locale)}>
                <Avatar
                  src={chatWidgetPhotoUrl}
                  alt={localize(partnerName, locale)}
                />
              </Tooltip>
            </Col>
            <Col flex="auto">
              <Row>
                <Text strong>
                  <Linkify text={localize(title, locale)} />
                </Text>
              </Row>
              <DateTime
                date={updatedAt}
                options={{ month: 'long', year: 'numeric', day: 'numeric' }}
              />
            </Col>
          </Space>
          <Row style={{ textAlign: 'justify' }}>
            <MarkdownRender content={localize(message, locale)} />
          </Row>
        </Space>
      </Card>
    </ListItem>
  );
};

NewsItem.propTypes = {
  item: PropTypes.shape({
    partner: PropTypes.shape({}).isRequired,
    title: PropTypes.shape({}),
    updatedAt: PropTypes.number,
    message: PropTypes.shape({}),
  }).isRequired,
  locale: PropTypes.string,
};

export default NewsItem;
