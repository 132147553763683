import React, { useMemo } from 'react';
import { Button, theme, Typography } from 'antd';
import { CoinStackOutlined } from '@guuru/react-icon';
import { useQuery } from '@apollo/client';
import { printBalances } from '@guuru/money-common';
import { GET_EXPERT } from './queries/expert';
import useCurrentUser from '../../hooks/useCurrentUser';
import useDrawers from '../../hooks/useDrawers';
import PayoutsView from '../../views/drawers/PayoutsView';

const { useToken } = theme;

const BalanceButton = function () {
  const { id: expertId } = useCurrentUser();
  const { token } = useToken();
  const { open } = useDrawers();

  const {
    data: {
      expert: {
        balances = [],
      } = {},
    } = {},
  } = useQuery(GET_EXPERT, {
    variables: {
      id: expertId,
    },
    fetchPolicy: 'cache-only',
  });

  const balance = useMemo(() => printBalances(balances), [balances]);

  return (
    <Button
      type="text"
      size="large"
      icon={<CoinStackOutlined />}
      style={{ color: token.colorIcon }}
      onClick={() => open(PayoutsView)}
    >
      <Typography.Text style={{ color: token.colorIcon }}>
        {balance}
      </Typography.Text>
    </Button>
  );
};

export default BalanceButton;
