import React from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Card, Input, Row, Select, Switch, Tooltip,
} from 'antd';
import { localesData, mobileLocales } from '@guuru/constants-common';
import {
  Field, FileInput, Form, useForm,
} from '@guuru/react-forms';
import moment from 'moment';
import { DatePicker } from '@guuru/react-date-picker';
import { useProfilePictureSubmit } from '@guuru/react-upload-hooks';
import GET_EXPERT from './queries/expert';
import UPDATE_NAME from './mutations/updateName';
import UPDATE_BIRTHDATE from './mutations/updateBirthDate';
import UPDATE_LOCALE from './mutations/updateLocale';
import UPDATE_PHOTO_URL from './mutations/updatePhotoUrl';
import CHANGE_EXPERT_STATUS from './mutations/changeExpertStatus';
import useCurrentUser from '../../../hooks/useCurrentUser';

const ProfileSettings = function ({ onboarding = false }) {
  const { id: expertId } = useCurrentUser();
  const [form] = useForm();

  const {
    data: {
      expert: {
        name,
        photoUrl,
        locale,
        isAvailable = false,
        account: {
          id: accountId,
          birthDate,
        } = {},
      } = {},
    } = {},
  } = useSuspenseQuery(GET_EXPERT, {
    variables: {
      id: expertId,
    },
  });

  const [updateName] = useMutation(UPDATE_NAME);
  const [updateLocale] = useMutation(UPDATE_LOCALE);
  const [updateBirthDate] = useMutation(UPDATE_BIRTHDATE);
  const [updatePhotoUrl] = useMutation(UPDATE_PHOTO_URL);
  const uploadProfilePicture = useProfilePictureSubmit({ id: expertId });
  const [changeExpertStatus, { loading }] = useMutation(CHANGE_EXPERT_STATUS);
  const status = isAvailable ? 'Available' : 'Away';
  const onFinish = async (values) => {
    const mutations = [
      updateName({
        variables: {
          id: expertId,
          name: values.name,
        },
      }),
      updateLocale({
        variables: {
          id: expertId,
          locale: values.locale,
        },
      }),
      updateBirthDate({
        variables: {
          accountID: accountId,
          accountInput: {
            birthDate: values.birthDate.format('Y-MM-DD'),
          },
        },
      }),
    ];
    if (values.profile_picture.url !== photoUrl) {
      const readUrl = await uploadProfilePicture(
        values.profile_picture.originFileObj,
      );
      mutations.push(
        updatePhotoUrl({
          variables: {
            id: expertId,
            photoUrl: readUrl,
          },
        }),
      );
    }
    await Promise.all(mutations);
  };

  const handleStatusChange = (isStatusChecked) => (
    changeExpertStatus({
      variables: {
        id: expertId,
        status: isStatusChecked ? 'always' : 'never',
      },
    })
  );

  // Can not select if not over 18 years old
  const disabledDate = (date) => {
    const eighteenYears = moment().subtract(18, 'years');
    return date && date > moment(eighteenYears);
  };

  return (
    <>
      {!onboarding && (
        <Card title="STATUS">
          <Row justify="space-between">
            {status}
            <Tooltip
              placement="right"
              title="When unchecked, new chats will not be assigned to you"
            >
              <Switch
                size="small"
                loading={loading}
                checked={isAvailable}
                onChange={handleStatusChange}
              />
            </Tooltip>
          </Row>
        </Card>
      )}
      <Card title={!onboarding ? 'INFO' : ''} bordered={!onboarding}>
        <Form
          form={form}
          onSave={onFinish}
          name="profileForm"
          savingMessage="Updating Profile settings..."
          savedMessage="Profile settings updated"
          initialValues={{
            name,
            profile_picture: {
              uid: photoUrl,
              name: photoUrl,
              status: 'done',
              url: photoUrl,
            },
            locale,
            birthDate: birthDate ? moment.utc(birthDate) : undefined,
          }}
        >
          <Row justify="center">
            <Field name="profile_picture">
              <FileInput maxSize={1024} shape="round" uploaderWidth="160px" />
            </Field>
          </Row>
          <Field
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input your name',
              },
            ]}
          >
            <Input />
          </Field>
          <Field
            required
            label="Birthdate"
            name="birthDate"
            rules={[
              {
                required: true,
                message: 'Please input your birthdate',
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              showNow={false}
              disabledDate={disabledDate}
              defaultPickerValue={birthDate ? moment.utc(birthDate) : undefined}
            />
          </Field>
          <Field
            name="locale"
            label="Preferred Language"
            rules={[
              {
                required: true, message: 'Please input your name!',
              },
            ]}
            help="Communications, like News and Alerts, will appear in this
          language (if available)"
          >
            <Select>
              {mobileLocales.map((countryCode) => (
                <Select.Option value={countryCode} key={countryCode}>
                  {localesData[countryCode].name}
                </Select.Option>
              ))}
            </Select>
          </Field>
        </Form>
      </Card>
    </>
  );
};

ProfileSettings.propTypes = {
  onboarding: PropTypes.bool,
};

export default ProfileSettings;
