import { gql } from '@apollo/client';

export default gql`
  query GetExpert($id: ID!) {
    expert(id: $id) {
      id
      name
      photoUrl
      locale
      isAvailable
      account {
        id
        birthDate
      }
    }
  }
`;
