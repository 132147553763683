import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { initSentry, Sentry } from '@guuru/sentry-web';
import { client } from '@guuru/graphql-web';
import { ApolloProvider } from '@apollo/client';
import ThemeProvider from '@guuru/theme-web';
import { colors } from '@guuru/constants-common';
import { i18n } from '@guuru/translation-web';
import { ErrorBoundary } from '@guuru/react-error-pages';
import { gtag } from '@guuru/analytics-web';
import { BrowserRouter } from 'react-router-dom';
import { theme, App as AntContext } from 'antd';
import MainView from '../views/MainView';
import GlobalStyle from './GlobalStyle';
import 'antd/dist/reset.css';
import { UserProvider } from '../context/authContext';

const { useToken } = theme;

if (process.env.EXPERT_WEB_GA_MEASUREMENT_ID) {
  gtag.initialize(process.env.EXPERT_WEB_GA_MEASUREMENT_ID, {
    debug_mode: process.env.ANALYTICS_DEBUG,
  });
}

if (process.env.EXPERT_WEB_SENTRY_DSN) {
  initSentry(process.env.EXPERT_WEB_SENTRY_DSN);
}

const ExpertWebApp = Sentry.withProfiler(() => {
  const { token } = useToken();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider
        theme={{ color: colors.blue, antd: token }}
        configProvider={{
          token: {
            fontSizeHeading4: 19,
            lineHeightHeading4: 1.2,
            fontWeightStrong: 500,
            colorBgLayout: 'white',
            colorLink: colors.blue,
          },
          components: {
            List: {
              paddingContentVertical: 0,
              lineWidth: 0,
            },
          },
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
            <GlobalStyle />
            <UserProvider>
              <BrowserRouter>
                <AntContext>
                  <MainView />
                </AntContext>
              </BrowserRouter>
            </UserProvider>
          </ErrorBoundary>
        </I18nextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<ExpertWebApp />);
