import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Pressable = styled(Link)`
  display: flex;
  border-color: ${({ theme, ...props }) => (props.$selected
    ? theme.primaryVariants[3]
    : 'transparent')};
  background-color: ${({ theme: { antd }, ...props }) => (props.$selected
    ? antd.controlItemBgActive
    : 'initial')};

  &:hover {
    background-color: ${({ theme: { antd }, ...props }) => (props.$selected
    ? antd.controlItemBgActiveHover
    : antd.controlItemBgHover)};
  }
`;

export default Pressable;
