import styled from 'styled-components';
import { Row } from 'antd';

const LoadingSpinner = styled(Row)`
  display: flex !important;
  width: 100%;
  height: 80% !important;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export default LoadingSpinner;
