import { gql } from '@apollo/client';

const GET_EXPERT = gql`
  query GetExpertAndBalance($id: ID!) {
    expert(id: $id) {
      id
      isAvailable
      rating(scale: [1,5])
      balances {
        amount
        currency
      }
    }
}`;

const BALANCE_SUBSCRIPTION = gql`
  subscription BalancesUpdated($expertId: ID!) {
    balancesUpdated(expertId: $expertId) {
      id
      balances {
        amount
        currency
      }
    }
  }
`;

export {
  GET_EXPERT,
  BALANCE_SUBSCRIPTION,
};
