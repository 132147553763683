import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UserAddOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Checkbox,
  Space,
  Spin,
} from 'antd';
import { localesData, locales } from '@guuru/constants-common';
import { error, loading, success } from '@guuru/react-message';
import { parse } from '@guuru/url-common';
import TermsOfServiceContent from '../TermsOfServiceContent';
import GET_EXPERT_INVITATION from './queries/expertInvitation';
import SIGNUP_WITH_INVITATION from './mutations/createNewExpertFromInvitation';

const FormItem = Form.Item;

const Header = styled(Space)`
  margin-bottom: 1rem;
  width: 100%;
`;

const Title = function ({ invitation = null }) {
  const text = invitation
    ? (
      <Alert
        message={`You were invited by ${invitation.partner?.name?.en}`}
        description="Fill the form to create your account."
        type="info"
        showIcon
      />
    )
    : (
      <Alert
        message="This validation code has expired"
        description="You can still fill the form to create an account."
        type="warning"
        showIcon
      />
    );

  return <Header direction="vertical">{text}</Header>;
};

Title.propTypes = {
  invitation: PropTypes.shape({
    partner: PropTypes.shape({
      name: PropTypes.shape({
        en: PropTypes.string,
      }),
    }),
  }),
};

const CreateAccount = function () {
  const [
    fetchInvitation, {
      loading: isLoading,
      data: {
        expertInvitation: invitation,
      } = {},
    },
  ] = useLazyQuery(GET_EXPERT_INVITATION, {
    fetchPolicy: 'network-only',
  });

  const [
    createExpert, {
      loading: isCreating,
      data: {
        createNewExpertFromInvitation: signedUpSuccessfully,
      } = {},
    },
  ] = useMutation(SIGNUP_WITH_INVITATION);

  useEffect(() => {
    const urlParams = parse(window.location.search);
    if (!urlParams?.id) return;

    fetchInvitation({ variables: { uid: urlParams.id } })
      .catch(({ message }) => error(message));
  }, [fetchInvitation]);

  const handleSubmit = async (values) => {
    const variables = {
      input: {
        uid: invitation?.uid,
        name: values.name,
        email: values.email,
        password: values.password,
        languages: values.languages,
      },
    };

    try {
      loading('Creating your account');
      await createExpert({ variables });
      success('Welcome to Guuru!');
    } catch (err) {
      error(err);
    }
  };

  if (isLoading) {
    return (
      <Row type="flex" justify="center">
        <Spin />
      </Row>
    );
  }

  if (signedUpSuccessfully) {
    return (
      <Typography>
        <br />
        <Col type="flex" align="middle">
          <p>Your account was created with success!</p>
          <Button href="../signin" type="ghost">
            Click here to sign in
          </Button>
        </Col>
      </Typography>
    );
  }

  return (
    <>
      <Title invitation={invitation} />

      <Form
        onFinish={handleSubmit}
        layout="vertical"
        className="ant-form-vertical"
      >
        <FormItem
          name="name"
          label="Name"
          validateTrigger="onSubmit"
          initialValue={invitation?.name || ''}
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input />
        </FormItem>
        <FormItem
          name="email"
          label="Email"
          initialValue={invitation?.email || ''}
          validateTrigger={['onSubmit', 'onBlur']}
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please input your E-mail!' },
          ]}
        >
          <Input type="email" disabled={!!invitation} />
        </FormItem>
        <FormItem
          name="password"
          label="Password"
          initialValue=""
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: 'Please input your password! It must have at least 6 characters.',
              min: 6,
            },
          ]}
        >
          <Input.Password autoFocus />
        </FormItem>
        <FormItem
          name="languages"
          label="Languages"
          rules={[
            {
              required: true,
              message: 'Please select at least one language',
              type: 'array',
            },
          ]}
        >
          <Select mode="multiple" placeholder="Please select languages">
            {locales.map((locale) => (
              <Select.Option
                key={locale}
                value={locale}
              >
                {localesData[locale].nameTranslate}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <Typography>
          <FormItem
            name="agreement"
            valuePropName="checked"
            style={{ textAlign: 'center' }}
            rules={[
              {
                validator: async (_, value) => {
                  if (!value) {
                    throw Error('You must accept the Terms and Conditions.');
                  }
                },
              },
            ]}
          >
            <Checkbox style={{ fontSize: '12px', lineHeight: 1.5 }}>
              <TermsOfServiceContent />
            </Checkbox>
          </FormItem>
        </Typography>

        <Col type="flex" align="middle">
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            style={{ margin: '8px' }}
            loading={isCreating}
            htmlType="submit"
          >
            Create Account
          </Button>
        </Col>
      </Form>
    </>
  );
};

export default CreateAccount;
