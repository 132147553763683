import React from 'react';
import {
  Col, Row, Space, Tag, Typography,
} from 'antd';
import { LocaleHelper } from '@guuru/helpers-common';
import { PartnerAvatar } from '@guuru/react-partner-logo';
import PropTypes from 'prop-types';
import useDrawers from '../../../../hooks/useDrawers';
import CommunityView
  from '../../../../views/drawers/CommunityView';
import Pressable from '../../../Pressable';
import ListItem from '../../../ListItem';
import DateTime from '../../../DateTime';
import PaddingX from '../../../PaddingX';

const { Text } = Typography;
const { localize } = LocaleHelper;

const bucketBadges = {
  pending: { messageKey: 'Pending', color: 'deepskyblue' },
  onboarding: { messageKey: 'Onboarding', color: 'deepskyblue' },
  inactive: { messageKey: 'Inactive', color: 'red' },
};

const CommunityListItem = function ({ partner }) {
  const {
    bucket,
    node: {
      id,
      name,
    } = {},
    submittedAt,
    joinedAt,
  } = partner;

  const { open } = useDrawers();
  const { messageKey, color } = bucketBadges[bucket] ?? { messageKey: '', color: '' };
  const dateTimeOptions = { month: 'long', year: 'numeric', day: 'numeric' };
  return (
    <Pressable key={id} onClick={() => open(CommunityView, { partnerId: id })}>
      <ListItem avatar={(
        <PaddingX left size="middle">
          <PartnerAvatar size="large" id={id} />
        </PaddingX>
      )}
      >
        <Col style={{ padding: '8px 0' }}>
          <Row justify="space-between" wrap={false}>
            <Col flex="auto">
              <Space size="small">
                <Text ellipsis style={{ fontSize: 16 }}>
                  {localize(name)}
                </Text>
                {color && <Tag color={color}>{messageKey}</Tag>}
              </Space>
            </Col>
          </Row>
          <Row wrap={false}>
            <Col flex="auto">
              {joinedAt && (
                <Text type="secondary">
                  {'Joined at '}
                  <DateTime date={joinedAt} options={dateTimeOptions} />
                </Text>
              )}
              {submittedAt && (
                <Text type="secondary">
                  {'Submitted at '}
                  <DateTime date={submittedAt} options={dateTimeOptions} />
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </ListItem>
    </Pressable>
  );
};

CommunityListItem.propTypes = {
  partner: PropTypes.shape({
    bucket: PropTypes.string,
    node: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.shape({}),
    }),
    roles: PropTypes.arrayOf(PropTypes.string),
    joinedAt: PropTypes.number,
    submittedAt: PropTypes.number,
  }).isRequired,
};

export default CommunityListItem;
