import React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import useDrawers from '../../../../hooks/useDrawers';
import JoinedCommunitiesView from '../../../../views/drawers/JoinedCommunitiesView';
import IconButton from '../../../IconButton';

const CommunitiesButton = function () {
  const { open } = useDrawers();

  return (
    <IconButton
      icon={<TeamOutlined />}
      onClick={() => open(JoinedCommunitiesView)}
    />
  );
};

export default CommunitiesButton;
