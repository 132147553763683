import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Card, Col, Space, Typography,
} from 'antd';

const { Title, Text } = Typography;

const PartnerCard = function ({
  name,
  description = undefined,
  avatarUri,
}) {
  return (
    <Card>
      <Col align="center">
        <Space direction="vertical" space="large">
          <Avatar
            src={avatarUri}
            style={{ width: 100, height: 100 }}
          />
          <Title level={3} style={{ color: 'black' }}>{name}</Title>
          <Text type="secondary">{description}</Text>
        </Space>
      </Col>
    </Card>
  );
};

PartnerCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  avatarUri: PropTypes.string.isRequired,
};

export default PartnerCard;
