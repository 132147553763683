import React from 'react';
import { Button, theme, Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import useCurrentUser from '../../hooks/useCurrentUser';
import { GET_EXPERT } from './queries/expert';
import useDrawers from '../../hooks/useDrawers';
import RatingView from '../../views/drawers/RatingView';

const { useToken } = theme;

const RatingButton = function () {
  const { id: expertId } = useCurrentUser();
  const { token } = useToken();
  const { open } = useDrawers();

  const {
    data: {
      expert: {
        rating,
      } = {},
    } = {},
  } = useQuery(GET_EXPERT, {
    variables: {
      id: expertId,
    },
    fetchPolicy: 'cache-only',
  });

  return (
    <Button
      type="text"
      size="large"
      icon={<StarFilled />}
      style={{ color: token.colorIcon }}
      onClick={() => open(RatingView)}
    >
      <Typography.Text style={{ color: token.colorIcon }}>
        {rating || '—'}/5
      </Typography.Text>
    </Button>
  );
};

export default RatingButton;
