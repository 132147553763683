import React from 'react';
import DrawerLayout from '../../containers/DrawerLayout';
import NewsList from '../../components/header/news/NewsList';

const { Title, Content } = DrawerLayout;

const NewsView = function () {
  return (
    <DrawerLayout>
      <Title>News</Title>
      <Content>
        <NewsList />
      </Content>
    </DrawerLayout>
  );
};

export default NewsView;
