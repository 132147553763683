import { useQuery, useSubscription } from '@apollo/client';
import {
  CHAT_DELETED_SUBSCRIPTION,
  CHAT_UPDATED_SUBSCRIPTION,
  GET_RESOLVED_CONVERSATIONS,
} from './queries/resolvedConversations';

const useResolvedConversations = function (expertId, {
  skipSubscription = false,
  pollInterval = undefined,
  filters,
}) {
  const {
    data: {
      expert: {
        chats: {
          count,
          pageInfo,
          edges: archivedChats,
        } = {},
      } = {},
    } = {},
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_RESOLVED_CONVERSATIONS, {
    variables: {
      id: expertId,
      isFirstPage: true,
      filters,
      status: ['rated', 'closed'],
    },
    fetchPolicy: skipSubscription ? 'cache-and-network' : 'cache-first',
    pollInterval,
  });

  useSubscription(CHAT_UPDATED_SUBSCRIPTION, {
    skip: skipSubscription,
    onData: ({ data: { data } }) => {
      if (['closed', 'rated'].includes(data?.chatUpdated?.status)) {
        refetch();
      }
    },
  });

  useSubscription(CHAT_DELETED_SUBSCRIPTION, {
    skip: skipSubscription,
    onData: () => refetch(),
  });

  return {
    pageInfo,
    archivedChats,
    loading,
    fetchMore,
    refetch,
    count,
  };
};

export default useResolvedConversations;
