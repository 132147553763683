import { gql } from '@apollo/client';
import expertPartnersFragment from '../../../../../../../graphql/fragments/expertPartnersFragment';

export default gql`
  ${expertPartnersFragment}
  mutation UpdatePartnerBio($expertId: ID!, $partnerId: ID!, $bio: String!) {
    updatePartnerBio(expertId: $expertId, partnerId: $partnerId, bio: $bio) {
      id
      ...ExpertPartners
    }
  }
`;
