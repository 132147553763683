import { gql } from '@apollo/client';

export const GET_RESOLVED_CONVERSATIONS = gql`
  query GetResolvedConversations(
    $id: ID!,
    $after: String,
    $status: [ChatStatus!]!,
    $filters: [ChatFilterType!],
    $isFirstPage: Boolean!
) {
    expert(id: $id) {
      id
      chats(
        first: 10,
        after: $after,
        status: $status,
        filters: $filters,
        period: null,
      ){
        count @include(if: $isFirstPage)
        edges {
          node {
            __typename
            id
            question
            status
            acceptedAt
            createdAt
            ratedAt
            isAutoRated
            languageCode
            lastSeenByExpertAt
            lastMessage {
              id
              text
              author {
                __typename
              }
            }
            rating
            feedback {
              topics {
                id
              }
              comment
            }
            user {
              id
              name
            }
            expert {
              id
            }
            category {
              id
              name
            }
            partner {
              id
              name
              settings {
                id
                appearance {
                  chatWidgetPhotoUrl
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const CHAT_DELETED_SUBSCRIPTION = gql`
  subscription ChatDeleted {
    chatDeleted
  }
`;

export const CHAT_UPDATED_SUBSCRIPTION = gql`
  subscription ChatUpdated {
    chatUpdated {
      id
      status
      feedback {
        topics {
          id
          title
          type
        }
        comment
      }
    }
  }
`;
