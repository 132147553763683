import { gql } from '@apollo/client';
import expertPartnersFragment from '../../../../../../../../graphql/fragments/expertPartnersFragment';

export default gql`
  ${expertPartnersFragment}
  mutation RemoveExpertFromRole($expertId: ID!, $partnerId: ID!, $role: PossibleRoles!) {
    removeExpertFromRole(expertId: $expertId, partnerId: $partnerId, role: $role) {
      id
      ...ExpertPartners
    }
  }
`;
