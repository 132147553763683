import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import ReactivateButton from './ReactivateExpertButton';
import LeavePartnerButton from './LeavePartnerButton';

const InactivityCard = function ({ partnerId, name, roles }) {
  return (
    <Card>
      <Row gutter={[16, 16]} justify="center">
        <Col span={16} flex="100%">
          <ReactivateButton partnerId={partnerId} name={name} />
        </Col>
        {roles.includes('guuru') && (
        <Col span={16} flex="100%">
          <LeavePartnerButton partnerId={partnerId} name={name} />
        </Col>
        )}
      </Row>
    </Card>
  );
};

InactivityCard.propTypes = {
  partnerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InactivityCard;
