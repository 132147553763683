import React from 'react';
import { List, Row } from 'antd';
import {
  BankOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import DrawerLayout from '../../../containers/DrawerLayout';
import useDrawers from '../../../hooks/useDrawers';
import AdvancedSettingsView from './AdvancedSettingsView';
import PaymentSettingsView from './PaymentSettingsView';
import AvailabilitySettingsView from './AvailabilitySettingsView';
import LanguageSettingsView from './LanguageSettingsView';
import ListItem from '../../../components/ListItem';
import Pressable from '../../../components/Pressable';
import PaddingX from '../../../components/PaddingX';

const { Title, Content } = DrawerLayout;

const data = [
  {
    label: 'Chat Languages',
    icon: GlobalOutlined,
    view: LanguageSettingsView,
  },
  {
    label: 'Availability',
    icon: ClockCircleOutlined,
    view: AvailabilitySettingsView,
  },
  {
    label: 'Payment',
    icon: BankOutlined,
    view: PaymentSettingsView,
  },
  {
    label: 'Advanced',
    icon: ExclamationCircleOutlined,
    view: AdvancedSettingsView,
  },
];

const Index = function () {
  const { open } = useDrawers();
  return (
    <DrawerLayout>
      <Title>Settings</Title>
      <Content>
        <List
          dataSource={data}
          renderItem={({ label, icon: Icon, view }) => (
            <Pressable onClick={() => open(view)} key={label}>
              <ListItem
                avatar={(
                  <PaddingX left size="large">
                    <Icon style={{ fontSize: 18 }} />
                  </PaddingX>
                )}
              >
                <Row style={{ padding: '8px 0' }}>{label}</Row>
              </ListItem>
            </Pressable>
          )}
        />
      </Content>
    </DrawerLayout>
  );
};

export default Index;
